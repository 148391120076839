import * as React from "react";

function SvgAvatar20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(20)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
        <rect
          id="avatar_(20)_svg__e"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
        <filter
          x="-118.2%"
          y="-210.9%"
          width="336.4%"
          height="690.5%"
          filterUnits="objectBoundingBox"
          id="avatar_(20)_svg__c"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M26 26.079c-1.636 0-2.962-.531-2.962-1.186 0-.654 1.326-1.185 2.962-1.185 1.636 0 2.962.53 2.962 1.185S27.636 26.08 26 26.08z"
          id="avatar_(20)_svg__d"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(20)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(20)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(20)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(20)_svg__b)">
          <g transform="translate(1 5)">
            <path
              d="M45.833 42.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M20.5 33.869c-2.667.087-6.051.652-10.154 1.693C6.244 36.603 2.962 40.416.5 47L4 58h22v-9.337l-1.25-1.67-4.25-13.124zM31.5 34c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H26v-9.336l1.25-1.67L31.5 34z"
              fill="#F5F5F5"
            />
            <ellipse
              fill="#F5F5F5"
              cx={25.98}
              cy={34.504}
              rx={5.923}
              ry={2.328}
            />
            <path
              d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fill="#A1684F"
            />
            <path
              d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fillOpacity={0.1}
              fill="#FFF"
            />
            <path
              d="M37 10.584V8.89C37 5.456 34.558 0 26 0S15 5.456 15 8.89v1.694s-1.692.125-1.692 1.27v4.686c0 1.145.423 2.088 1.692 2.088 0 5.724 5.087 12.277 11 12.277s11-6.553 11-12.277c1.27 0 1.692-.943 1.692-2.088v-4.686c0-1.145-1.692-1.27-1.692-1.27z"
              fill="#B7775B"
            />
            <use
              fill="#000"
              filter="url(#avatar_(20)_svg__c)"
              xlinkHref="#avatar_(20)_svg__d"
            />
            <use fill="#E88579" xlinkHref="#avatar_(20)_svg__d" />
            <path
              d="M16.27 39.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L26 48.686C23.885 45.3 20.923 33.87 20.923 32.6c-1.92 1.093-4.654 3.527-4.654 7.197zM35.73 39.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L26 48.686C28.115 45.3 31.077 33.87 31.077 32.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
          <path
            d="M23.778 14.407c3.135 1.897 7.37 1.872 10.48-.063.847.587 1.694 1.174 2.377 1.923.683.76 1.201 1.71 1.239 2.733.215-.012.398.012.626 0 0-1 0-1-.024-2.15-.023-1.151.346-1.467.447-2.194a7.038 7.038 0 00-.91-4.618c.898-1.872 1.011-4.306.202-6.216a4.84 4.84 0 01-1.985 1.56A7.85 7.85 0 0036.445 2c-.543.824-1.479 1.36-2.44 1.61-.973.25-1.984.25-2.983.2-1.783-.1-3.552-.362-5.348-.387-1.782-.025-3.615.212-5.195 1.036-1.58.824-2.87 2.321-3.11 4.069-1.15.45-1.91 1.41-2.2 2.596-.29 1.186-.164 2.422.063 3.62.075.393.224.781.242 1.178.014.303.022 1.082.026 2.338.515-.015.848-.018 1-.01 1.707-.811 3.27-2.545 3.119-4.405 1.024.886 2.212 1.523 3.477 2.035a4.395 4.395 0 01-.152-1.985c.265.15.569.35.834.512z"
            fill="#463B3B"
          />
          <g transform="translate(21 18)">
            <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
            <circle fill="#000" cx={1.633} cy={1} r={1} />
          </g>
          <g transform="translate(29.163 18)">
            <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
            <circle fill="#000" cx={1.633} cy={1} r={1} />
          </g>
        </g>
        <g>
          <mask id="avatar_(20)_svg__f" fill="#fff">
            <use xlinkHref="#avatar_(20)_svg__e" />
          </mask>
          <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(20)_svg__e" />
          <g fillRule="nonzero" mask="url(#avatar_(20)_svg__f)">
            <g transform="translate(1 5)">
              <path
                d="M45.833 42.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
                fill="#CFCFCF"
              />
              <path
                d="M20.5 33.869c-2.667.087-6.051.652-10.154 1.693C6.244 36.603 2.962 40.416.5 47L4 58h22v-9.337l-1.25-1.67-4.25-13.124zM31.5 34c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H26v-9.336l1.25-1.67L31.5 34z"
                fill="#F5F5F5"
              />
              <ellipse
                fill="#F5F5F5"
                cx={25.98}
                cy={34.504}
                rx={5.923}
                ry={2.328}
              />
              <path
                d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
                fill="#A1684F"
              />
              <path
                d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
                fillOpacity={0.1}
                fill="#FFF"
              />
              <path
                d="M37 10.584V8.89C37 5.456 34.558 0 26 0S15 5.456 15 8.89v1.694s-1.692.125-1.692 1.27v4.686c0 1.145.423 2.088 1.692 2.088 0 5.724 5.087 12.277 11 12.277s11-6.553 11-12.277c1.27 0 1.692-.943 1.692-2.088v-4.686c0-1.145-1.692-1.27-1.692-1.27z"
                fill="#B7775B"
              />
              <path
                d="M26 26.079c-1.636 0-2.962-.531-2.962-1.186 0-.654 1.326-1.185 2.962-1.185 1.636 0 2.962.53 2.962 1.185S27.636 26.08 26 26.08z"
                fill="#E88579"
              />
              <path
                d="M16.27 39.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L26 48.686C23.885 45.3 20.923 33.87 20.923 32.6c-1.92 1.093-4.654 3.527-4.654 7.197zM35.73 39.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L26 48.686C28.115 45.3 31.077 33.87 31.077 32.6c1.92 1.093 4.654 3.527 4.654 7.197z"
                fill="#E6E6E6"
              />
            </g>
            <path
              d="M23.778 14.407c3.135 1.897 7.37 1.872 10.48-.063.847.587 1.694 1.174 2.377 1.923.683.76 1.201 1.71 1.239 2.733.215-.012.398.012.626 0 0-1 0-1-.024-2.15-.023-1.151.346-1.467.447-2.194a7.038 7.038 0 00-.91-4.618c.898-1.872 1.011-4.306.202-6.216a4.84 4.84 0 01-1.985 1.56A7.85 7.85 0 0036.445 2c-.543.824-1.479 1.36-2.44 1.61-.973.25-1.984.25-2.983.2-1.783-.1-3.552-.362-5.348-.387-1.782-.025-3.615.212-5.195 1.036-1.58.824-2.87 2.321-3.11 4.069-1.15.45-1.91 1.41-2.2 2.596-.29 1.186-.164 2.422.063 3.62.075.393.224.781.242 1.178.014.303.022 1.082.026 2.338.515-.015.848-.018 1-.01 1.707-.811 3.27-2.545 3.119-4.405 1.024.886 2.212 1.523 3.477 2.035a4.395 4.395 0 01-.152-1.985c.265.15.569.35.834.512z"
              fill="#463B3B"
            />
            <g transform="translate(21 18)">
              <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
              <circle fill="#000" cx={1.633} cy={1} r={1} />
            </g>
            <g transform="translate(29.163 18)">
              <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
              <circle fill="#000" cx={1.633} cy={1} r={1} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar20;
