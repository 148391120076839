import * as React from "react";

function SvgBudget(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 24" {...props}>
      <g
        stroke="#FFF"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        opacity={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.91 9.895H8.359a1.658 1.658 0 100 3.316h2.368a1.658 1.658 0 010 3.315H6.7M9.542 8L9.5 18.5M7.182 1.773h4.636M9.5 4.864V1.773M9.5 7.182h0M9.5 19.545h0M15.682 13.364h0M3.318 13.364h0" />
        <g>
          <path d="M3.35 22.597l-1.5-2.598 2.598-1.5" />
          <path d="M1.556 14.972a8.1 8.1 0 115.873 6.337l-5.579-1.31" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBudget;
