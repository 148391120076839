import * as React from "react";

function SvgAvatar1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(1)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(1)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(1)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(1)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(1)_svg__b)">
          <path
            d="M42.218 21.16c.167-.89.259-1.806.273-2.743.004-.09.008-.181.008-.258 0-1.088-.107-2.11-.31-3.068C40.798 8.5 34.862 5 27.75 5 19.603 5 13 9.593 13 18.159c0 1.033.098 2.043.282 3.02.795 6.24 2.883 18.482.84 20.722 2.256 2.473 13.627 0 13.627 0s11.372 2.473 13.627 0c-2.045-2.242.048-14.508.842-20.742z"
            fill="#5A4B4B"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#D9BFB8"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#E6CBC3"
          />
          <path
            d="M42 18.16C42 9.592 35.23 5 26.878 5c-.296 0-.588.008-.879.019 7.942.312 14.242 4.877 14.242 13.14 0 .077-.003.169-.007.259a15.86 15.86 0 01-.28 2.742c-.814 6.234-2.959 18.5-.864 20.742-.664.71-2.102 1.011-3.811 1.081 2.439.086 4.685-.137 5.568-1.081-2.095-2.242.05-14.508.865-20.742.17-.89.265-1.806.28-2.742.003-.09.007-.182.007-.259z"
            fill="#463B3B"
          />
          <path
            d="M28.686 14.214C28.15 21.786 15 19.946 15 24.962c1.457 6.029 6.408 10.467 12.293 10.467 6.96 0 12.612-6.206 12.725-13.909-6.536-.805-10.409-6.177-11.332-7.306z"
            fill="#FFE1D9"
          />
          <path
            d="M28.686 14.214C28.15 21.786 15 19.946 15 24.962c.023.096.049.189.074.283 1.029-4.291 13.664-2.773 13.612-11.03z"
            fill="#F2D6CE"
          />
          <path
            d="M28.686 14.214c.923 1.129 3.486 7.18 10.022 7.985-.11 7.453-5.383 12.87-12.03 13.212.204.01.409.018.616.018 6.96 0 12.612-6.206 12.724-13.909-6.536-.805-10.409-6.177-11.332-7.306z"
            fill="#F2D6CE"
          />
          <path
            d="M32.842 29.707c.243.37-2.094 2.463-5.549 2.463-3.455 0-5.792-2.092-5.548-2.463.244-.371 2.601.856 5.548.856s5.306-1.227 5.55-.856z"
            fill="#E6BAAD"
          />
          <g transform="translate(20.293 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g transform="translate(29.826 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g>
            <path
              d="M46.833 47.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M21.5 38.869c-2.667.087-6.051.652-10.154 1.693C7.244 41.603 3.962 45.416 1.5 52L5 63h22v-9.337l-1.25-1.67-4.25-13.124zM32.5 39c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H27v-9.336l1.25-1.67L32.5 39z"
              fill="#F5F5F5"
            />
            <path
              d="M17.27 44.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L27 53.686C24.885 50.3 21.923 38.87 21.923 37.6c-1.92 1.093-4.654 3.527-4.654 7.197zM36.73 44.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L27 53.686C29.115 50.3 32.077 38.87 32.077 37.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar1;
