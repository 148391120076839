import React from 'react';
import styles from './PatientCard.module.scss';
import SvgIcon from '../SvgIcon';

/**
 * @param {Object} patient - patient to render in the Card
 * @example <PatientCard patient={patient}  />)
 */

const diseaseNamesMap = { healthy: 'Healthy', cold: 'Cold', flu: 'Flu', bronchitis: 'Bronchitis', lung_cancer: 'Cancer' };

const PatientCardStats = ({ patient }) => {
  return (
    <div className={styles.cardPatientStat}>
      <div className={styles.cardHeader}>
        <h3>
          <span className={styles.avatar} alt={patient.name}>
            <SvgIcon tag={patient.avatar} className="" />
          </span>
          {patient.name}
        </h3>
      </div>
      <table className={styles.styledTable}>
        <tbody>
          <tr className={`highlight-treatment ${patient.disease}_class`}>
            <td className={styles.rowTitleLong}>
              <SvgIcon tag="disease" title="Disease" />
              <span>Disease</span>
            </td>
            <td>{diseaseNamesMap[patient.disease]}</td>
          </tr>
          <tr className={`highlight-treatment ${patient.treated_disease}_class`}>
            <td className={styles.rowTitleLong}>
              <SvgIcon tag="treated_disease" title="Treated Disease" />
              <span>Treated Disease</span>
            </td>
            <td>{diseaseNamesMap[patient.treated_disease]}</td>
          </tr>
          <tr>
            <td className={styles.rowTitleLong}>
              <SvgIcon tag="true_life_gain" title="True Life Gain" />
              <span>True Life Gain</span>
            </td>
            <td>{patient.true_life_gain}</td>
          </tr>
          <tr>
            <td className={styles.rowTitleLong}>
              <SvgIcon tag="maximal_life_gain" title="Maximal Possible Life Gain" />
              <span>Maximal Life Gain</span>
            </td>
            <td>{patient.maximal_life_gain}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PatientCardStats;
