import * as React from "react";

function SvgHealthy(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 93 106" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M92.436 58.932c0 12.777-8.378 30.435-18.508 40.081-4.492 4.284-9.33 6.987-13.937 6.987-2.91 0-5.815-.464-8.313-1.212a18.22 18.22 0 00-10.485 0A29.538 29.538 0 0132.88 106c-4.826 0-9.91-2.97-14.583-7.615C8.459 88.616.436 71.435.436 58.932c0-18.448 11.126-29.593 21.588-32.283 5.012-1.289 10.827-.458 15.523.753a35.477 35.477 0 0017.776 0c4.697-1.211 10.51-2.042 15.523-.753 10.463 2.69 21.59 13.836 21.59 32.283z"
          fill="#FCA797"
        />
        <path
          d="M74.436 99.012c-12.518 2.179-22.158 1.575-29.422-.157-11.862-2.83-20.686-12.684-22.157-24.618C19.174 44.35 39 35.647 39 35.647c5.811 2.6 12.345 1.212 12.345 1.212-4.17-4.104 1.384-8.826 1.384-8.826a35.961 35.961 0 01-14.925-.63c-4.73-1.212-10.584-2.043-15.63-.754C11.638 29.34.435 40.485.435 58.932.436 77.376 18.016 106 33.103 106c2.932 0 5.855-.464 8.37-1.212a18.468 18.468 0 0110.559 0 29.934 29.934 0 008.37 1.212c4.638 0 9.51-2.703 14.034-6.987z"
          fill="#FB816B"
        />
        <path
          d="M51.436 16.233S53.115 4.236 61.958.743c8.454-3.338 15.99 5.689 19.081 4.978a1.22 1.22 0 00.397-.159l-.002.007c-.104.324-3.494 10.674-12.2 11.352-8.844.689-13.656-3.442-17.798-.688z"
          fill="#87D8C4"
        />
        <path
          d="M51.436 16.24s5.373-6.65 12.928-6.65c6.645 0 14.803-3.033 16.675-3.766.225-.087.358-.142.39-.154a.016.016 0 00.007-.003l-.002.006c-.104.321-3.494 10.577-12.2 11.249-8.844.682-13.656-3.41-17.798-.682z"
          fill="#6CD0B7"
        />
        <path
          d="M55.436 33c0 2.425-3.277 4.444-7.626 4.902a18.171 18.171 0 01-3.827-.006c-4.31-.476-7.547-2.486-7.547-4.896h19z"
          fill="#F9836E"
        />
        <path
          d="M53.124 15.094l-6.876 11.059c.515 2.241.96 4.712 1.25 7.35.158 1.425.271 2.897.324 4.41a19.656 19.656 0 01-3.776-.005 54.25 54.25 0 00-.347-4.405c-1.232-10.337-4.998-17.952-5.043-18.037-.488-.974-.138-2.183.782-2.7.92-.518 2.061-.148 2.549.826.11.224 1.496 3.027 2.894 7.5l5.096-8.195c.57-.92 1.742-1.174 2.61-.567.87.605 1.11 1.843.537 2.764z"
          fill="#6F4410"
        />
      </g>
    </svg>
  );
}

export default SvgHealthy;
