import * as React from "react";

function SvgLungCancerGray(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 72 106" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M65.906 32.2L46.617 32v-8h-22v8l-18.788.2a5.464 5.464 0 00-5.462 5.467v57.4C.367 101.105 5.257 106 11.29 106h49.154c6.033 0 10.923-4.895 10.923-10.933v-57.4a5.464 5.464 0 00-5.461-5.467z"
          fill="#CDD1D7"
          opacity={0.8}
        />
        <path
          d="M71.367 83.462S56.02 84 34.174 84C12.328 84 .367 83.462.367 83.462V49s14.692.538 33.807.538c19.116 0 37.193-.538 37.193-.538v34.462z"
          fill="#9CA3B0"
        />
        <path
          d="M20.367 49.432V84h13.954c21.76 0 37.046-.54 37.046-.54V49s-18.006.54-37.046.54c-5.032 0-9.738-.054-13.954-.108z"
          fillOpacity={0.3}
          fill="#FFF"
        />
        <path
          d="M7.367 0h56a2 2 0 012 2v17a5 5 0 01-5 5h-50a5 5 0 01-5-5V2a2 2 0 012-2z"
          fill="#404D66"
          opacity={0.5}
        />
        <path
          d="M5.367 10v8.4c0 3.093 2.442 5.6 5.455 5.6h49.09c3.013 0 5.455-2.507 5.455-5.6V10h-60z"
          fill="#FFF"
          opacity={0.5}
        />
      </g>
    </svg>
  );
}

export default SvgLungCancerGray;
