import * as React from "react";

function SvgDisease20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(3.2 2.5)"
        stroke="#FB816B"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={8.769} cy={4.385} r={4.385} />
        <path d="M13.154 4.385h2.923c.804 0 1.461.657 1.461 1.461v11.692c0 .804-.657 1.462-1.461 1.462H1.462A1.466 1.466 0 010 17.538V5.846c0-.804.658-1.461 1.462-1.461h2.923M8.769 19V8.769" />
        <path d="M9.28 4.896a.706.706 0 01-1.022 0 .706.706 0 010-1.023c.292-.292 2.046-1.023 2.046-1.023s-.73 1.754-1.023 2.046z" />
      </g>
    </svg>
  );
}

export default SvgDisease20;
