import * as React from "react";

function SvgEsl(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 21" {...props}>
      <g
        stroke="#FFF"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        opacity={0.61}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.22 8.125h0a33.27 33.27 0 01-14.25 0h0l1.425-5.78h0a13.09 13.09 0 0111.4 0h0l1.425 5.78zM14.795 11.133c.475-.554.95-.791 1.425-.633.713.317.95 1.583.555 2.85-.396 1.267-1.346 2.138-2.059 1.82 0 0-.079 0-.079-.078-.633 2.77-2.85 4.829-5.542 4.829-2.691 0-4.987-2.058-5.541-4.83l-.08.08c-.712.317-1.662-.554-2.058-1.821-.475-1.267-.237-2.533.554-2.85.475-.158 1.03.08 1.425.633M9.095 3.375v3.167M7.512 4.958h3.167" />
      </g>
    </svg>
  );
}

export default SvgEsl;
