import * as React from "react";

function SvgFlu20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        stroke="#A681EF"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.74 2.954l-9.756 9.273-.57 2.705L2 19.336 4.276 21.5l4.635-3.245 2.845-.541 9.756-9.273c.65-.618.65-1.546 0-2.164l-3.414-3.245a1.659 1.659 0 00-2.358-.078z" />
        <path d="M14.748 8.256l1.131 1.102-4.525 4.41-1.132-1.103zM18 6.5l-1 1M4 16.5l3 3" />
      </g>
    </svg>
  );
}

export default SvgFlu20;
