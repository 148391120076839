import React from 'react';
import SvgIcon from './SvgIcon';
import PatientCardStats from './PatientCard/PatientCardStats';
import Header from "./Header/Header";

/**
 * @param {Array} props.results
 * @param {Object} props.gameSummary
 * @param {Number} props.round
 * @param {Array} props.patients -
 * @example <RoundSummary results={results} gameSummary={gameSummary} round={round} patients={patients}  />)
 */

const RoundSummary = ({ results, round, gameSummary, patients, budget, explanation, difficulty, children }) => {
  if (!results) {
    return '';
  }
  const { cost, expected_life_gain, true_life_gain } = results;
  const { maximal_total_life_gain, num_rounds, optimal_strategy_total_life_gain, player_total_life_gain } = gameSummary;

  const roundTo = (num) => {
    if (num !== null) return num.toFixed(2);
  };

  const renderPatients = () => {
    return (
      <React.Fragment>
        {/*<h2 className="mb-lg">Patients</h2>*/}
        <div className="container-cards">
          {patients.map((patient, index) => {
            return <PatientCardStats patient={patient} key={index} />;
          })}
        </div>
      </React.Fragment>
    );
  };

  const renderExplanation = () => {
    return explanation;
  };

  const renderBudget = (budget) => {
    if (budget > 900) {
      return '∞';
    }
    return roundTo(budget);
  };

  const renderRounds = (rounds) => {
    if (typeof rounds === 'string') {
      return '1';
    }
    return rounds;
  };

  function renderRoundNumber(round) {
    if (typeof round === 'string') {
      return round + " round";
    }
    return "Round " + round;
  }

  const statusOfAllRounds = () => {
    return (
      <React.Fragment>
        {/*<h2 className="mb-lg">Results of all previous rounds</h2>*/}
        <div className="footer">
          <div className="footer-single">
            <span className="big">{renderRounds(num_rounds)}</span>
            <span className="small">Number of Rounds</span>
          </div>
          <div className="footer-single">
            <span className="big">{maximal_total_life_gain}</span>
            <span className="small">Maximal Total Life Gain</span>
          </div>
          <div className="footer-single">
            <span className="big"> {optimal_strategy_total_life_gain}</span>
            <span className="small">Optimal Strategy Total Life Gain</span>
          </div>
          <div className="footer-single">
            <span className="big">{player_total_life_gain}</span>
            <span className="small">Player Total Life Gain</span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const statsBox = () => {
    return (
      <React.Fragment>
        <div className="stats-box px-sm">
          {/*<div className="mx-md">Statistics: </div>*/}
          <div className="tooltip">
            <SvgIcon tag="budget" svgClass="icon-xs-white"/>
            <span className="tooltiptext">Budget</span>
          </div>
          <span>{renderBudget(budget)}</span>
          <div className="tooltip">
            <SvgIcon tag="esl" svgClass="icon-xs-white"/>
            <span className="tooltiptext">Expected Life Gain</span>
          </div>
          <span>{roundTo(expected_life_gain)}</span>
          <div className="delimiter-white smaller"/>
          <div className="tooltip">
            <SvgIcon tag="cost" svgClass="icon-xs-white"/>
            <span className="tooltiptext">Cost of Selected Treatments</span>
          </div>
          <span>{cost}</span>
          <div className="tooltip">
            <SvgIcon tag="true_life_gain_white" svgClass="icon-xs-white"/>
            <span className="tooltiptext">True Life Gain</span>
          </div>
          <span>{true_life_gain}</span>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Header title={renderRoundNumber(round)}
              subtitle={difficulty && <span className="level"> level: {difficulty}</span>}
              middle={statsBox()}
              right={children}>
      </Header>
      <div className="App-center mb-lg">
        <div>
          {renderExplanation()}
          <section>{patients && renderPatients()}</section>
        </div>
      </div>
      {statusOfAllRounds()}
    </React.Fragment>
  );
};

export default RoundSummary;
