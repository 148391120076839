import React from 'react';
import './Intro.scss';
import SvgIcon from "../SvgIcon";
import GameJS from '../../utils/GameJS';
import {diseaseNamesMap, Game} from '../Game';
import {IntroScreen, WelcomeScreen} from './IntroScreen';
import ColorCodedCard from './ColorCodedCard';
import {ReactComponent as ColorCodedSampleConf} from '../../images/color_coded_card_conf.svg';
import {ReactComponent as ColorCodedSampleLG} from '../../images/color_coded_card_LG.svg';
import {ReactComponent as ColorCodedSampleELG} from '../../images/color_coded_card_ELG.svg';
import TreatmentCard from "../TreatmentCard/TreatmentCard";
import styles from "../PatientCard/PatientCard.module.scss";

/**
 *
 * Intro Screen that serves to preload the files
 * @param {Function} gotoGame - click handler for the main button
 * @example <Intro  gotoGame={gotoGame}/>)
 */

// HACK: I can't find a place to import them. Seems like they are generated dynamically for each round
const treatmentsForIllustration = [{name: "healthy", cost: 0}, {name: "cold", cost: 1},
  {name: "flu", cost: 3}, {name: "bronchitis", cost: 5}, {name: "lung_cancer", cost: 8}]


const Intro = ({ gotoGame }) => {
  let [stage, setStage] = React.useState(0);

  const nextStage = (arg) => {
    /* if we call this with argument 0, then just resetIntro*/
    if (!arg) {
      resetStage();
      return;
    }
    let nextStage = stage + 1;
    setStage(nextStage);
  };

  const previousStage = (arg) => {
    /* if we call this with argument 0, then just resetIntro*/
    if (!arg) {
      resetStage();
      return;
    }
    let nextStage = stage - 1;
    setStage(nextStage);
  };

  const resetStage = () => {
    setStage(0);
  };

  const mainRender = (stage) => {
    let renderStage;
    switch (stage) {
      case 0:
        const welcomeImage =
            <div>
              <div className="shelfWelcome">
                <div className="shelf-content">
                  {treatmentsForIllustration.map((item) => {
                    return (
                      //  without the key I get a warning: Each child in a list should have a unique "key" prop
                      <div key={item.name} >
                        <TreatmentCard name={diseaseNamesMap[item.name]} cost={item.cost} image={item.name}
                                       forceColor={true} draggable={false} suppressNote={true}/>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="smallAvatar">
                <span className={styles.avatar}>
                  <SvgIcon tag="Avatar_female1" className="" />
                </span>
                <span className={`${styles.avatar} greenBackground`}>
                  <SvgIcon tag="cost"/>
                </span>
                <span className={styles.avatar}>
                  <SvgIcon tag="Avatar_male1" className="" />
                </span>
              </div>
            </div>
        renderStage = (
          <WelcomeScreen title=""
                       middle="The Calibration Game"
                       action1={{ handler: gotoGame, title: 'Skip Intro' }}
                       action2={{ handler: nextStage, title: 'Continue' }}
                       welcomeImage={welcomeImage}
                       align="justify">

              <p>
                Hello my friend! Today will be your hardest day of work as
                hospital administrator yet. The doctors are all gone and you
                will have to treat patients yourself. Luckily your AI
                assistant <strong>K.A.L.E.</strong> is there to provide you with
                diagnoses. The only question is: how much can you trust her?
              </p>
              <p>
                In the following intro we will guide you through the calibration
                game. Use the button above to skip it if you already know how
                everything works.
              </p>
          </WelcomeScreen>
        );
        break;
      case 1:
        renderStage = (
          <IntroScreen title="Intro"
                       middle="Patient cards"
                       action1={{ handler: gotoGame, title: 'Skip Intro' }}
                       action2={{ handler: nextStage, title: 'Continue' }}
                       action3={{ handler: previousStage, title: 'Back'}}
                       align="left">
            <div className="Intro-justify">
              <ColorCodedCard color="secondary" title="Confidences">
                <p>
                  This column is the output sent by K.A.L.E. - the values
                  are the AI's confidences about the patient's condition. For
                  simplicity we consider only four possible diseases.
                </p>
              </ColorCodedCard>
            </div>
            <div className="colorCodedImage">
              <ColorCodedSampleConf/>
            </div>
          </IntroScreen>
        );
        break;
      case 2:
        renderStage = (
            <IntroScreen title="Intro"
                         middle="Patient cards"
                         action1={{handler: gotoGame, title: 'Skip Intro'}}
                         action2={{handler: nextStage, title: 'Continue'}}
                         action3={{handler: previousStage, title: 'Back'}}
                         align="left">
              <div className="Intro-justify">
                <br/>
                <ColorCodedCard color="primary" title="Life Gain">
                  <p>
                    This column represents the additional life expectancy that
                    is gained for the patient if they indeed have the underlying
                    condition and are correctly treated for it. For example, if
                    the true disease is flu, the "Life Gain" value for flu is 20
                    and the patient is treated for flu, you have just increased
                    the patient's life expectancy by 20 months. However, if you
                    treat them for, say, cancer, the treatment's true effect on
                    the life expectancy will be zero, irrespective of the value
                    of "Life Gain" for cancer.
                  </p>
                  <p>
                    Since each patient has different preconditions, values in
                    this column will vary. Of course, if the patient is healthy,
                    no additional life expectancy can be gained (we do not take
                    into account negative effects due to incorrect treatments),
                    so the value for healthy is always zero.
                  </p>
                </ColorCodedCard>
              </div>
              <div className="colorCodedImage">
                <ColorCodedSampleLG/>
              </div>
            </IntroScreen>
        );
        break;
      case 3:
        renderStage = (
            <IntroScreen title="Intro"
                         middle="Patient cards"
                         action1={{handler: gotoGame, title: 'Skip Intro'}}
                         action2={{handler: nextStage, title: 'Continue'}}
                         action3={{handler: previousStage, title: 'Back'}}
                         align="left">
              <div className="Intro-justify">
                <br/><br/><br/>
                <ColorCodedCard color="contrast" title="Expected Life Gain">
                  <p>
                    This column is simply the product of the fist two and
                    represents the <i>expected life gain</i>. That is, it would
                    represent the expected life gain if the confidences output
                    by K.A.L.E. were the true probabilities of the
                    patient-disease distribution (i.e. if the diagnosis
                    predictor is calibrated). Without additional information or
                    intuition, the optimal treatment strategy should be to
                    maximise the <i>total expected life gain for all patients
                    per round</i>.
                  </p>
                </ColorCodedCard>
              </div>
              <div className="colorCodedImage">
                <ColorCodedSampleELG/>
              </div>
            </IntroScreen>
        );
        break;
      case 4:
        renderStage = (
          <IntroScreen title="Intro"
                       middle="Unlimited budget"
                       action1={{ handler: gotoGame, title: 'Skip Intro' }}
                       action2={{ handler: nextStage, title: 'Continue' }}
                       action3={{handler: previousStage, title: 'Back'}}
                       align="justify">
            <h2>Round 1: Unlimited budget</h2>
            <p>
              With no budget restrictions you can give each patient the optimal
              treatment. Note that optimal (in expectation) does not necessarily
              mean that the treatment will be correct - you still might end up
              with no additional life gain.
            </p>
            <p>
              Try it now: Click "Continue" and drag treatments to the patient
              cards. You must treat all patients in order to finish the round.
            </p>
          </IntroScreen>
        );
        break;
      case 5:
        const explanation1 = (
          <React.Fragment>
            {/*<h2 className="mb-lg">Results of Intro Round: Unlimited Budget</h2>*/}
            <p className="broad-text">
              The optimal strategy was to assign the treatment maximizing the
              ELG for each patient. Since we have no budget limit, it never
              makes sense to treat the patient as healthy, as this cannot
              result in an additional life gain (in this game!).
              Therefore, it was "optimal" to treat Marilyn for bronchitis
              even though she was most probably healthy. This strategy also makes
              a mistake for Stephen: he is treated for flu even though he has a
              cold.
            </p>
          </React.Fragment>
        );
        // here Budget 1000 gets rendered as infinity
        renderStage = <Game provider={GameJS}
                            js={true}
                            mockRoundNumber={1}
                            exitGame={nextStage}
                            budget={1000}
                            explanation={explanation1} />;
        break;
      case 6:
        renderStage = (
          <IntroScreen title="Intro"
                       middle="Unlimited budget"
                       action1={{handler: gotoGame, title: 'Skip Intro'}}
                       action2={{handler: nextStage, title: 'Continue'}}
                       action3={{handler: previousStage, title: 'Back'}}
                       align="justify">
            <p>Did you make the "optimal" decisions?

              Note that it is possible for the player to outperform the optimal
              strategy from time to time by chance. On average, however,
              maximising the total expected life gain is the strictly best
              strategy in the present difficulty (miscalibration) setting,
              provided that K.A.L.E. gives calibrated diagnosis predictions. In
              the hard setting, in principle it is possible to beat the naive
              optimal strategy by recalibrating.
            </p>
          </IntroScreen>
        );
        break;
      case 7:
        renderStage = (
          <IntroScreen title="Intro"
                       middle="Limited budget"
                       action1={{ handler: gotoGame, title: 'Skip Intro' }}
                       action2={{ handler: nextStage, title: 'Continue' }}
                       action3={{ handler: previousStage, title: 'Back' }}
                       align="justify">
            <h2>Round 2: limited budget</h2>
            <p>
              Now we come to the essence of the game: you have to make decisions
              with limited budget. Normally you will not have enough budget to
              give each patient the best possible treatment and will have to
              make some hard decisions. The optimal strategy is still to
              maximise the total expected life gain of all patients but you can
              no longer reduce this to maximising each patient's individual
              expected life gain. Instead, you will now have to look at all
              patients as a group and make some hard, triage-like, decisions.
            </p>
            <p>
              Don't miss the fields indicating the remaining budget and the
              expected life gain of your current selection. Let's see if you
              make the optimal choice!
            </p>
          </IntroScreen>
        );
        break;
      case 8:
        const explanation2 = (
          <React.Fragment>
            {/*<h2 className="mb-lg">Results of Intro Round: Restricted Budget</h2>*/}
            <p className="broad-text">
              Now the optimal solution involved making hard choices. Let us
              assume that you follow the strategy of maximising ELG. You would
              not treat Susan for bronchitis (even though this would be the
              recommended treatment for her with unlimited budget) because in
              expectation it was more beneficial to treat Roberta for a cold
              and you could not afford to treat both. So instead you treated
              Susan for flu, because it's cheaper, and got it wrong. Now in
              reality Roberta was healthy so your treatment for her did not
              result in additional life gain.
            </p>
          </React.Fragment>
        );
        renderStage = <Game provider={GameJS}
                            js={true}
                            mockRoundNumber={2}
                            exitGame={nextStage}
                            explanation={explanation2} />;
        break;
      case 9:
        renderStage = (
          <IntroScreen title="Intro"
                       middle="Limited budget"
                       action1={{handler: gotoGame, title: 'Skip Intro'}}
                       action2={{handler: nextStage, title: 'Continue'}}
                       action3={{handler: previousStage, title: 'Back'}}
                       align="justify">
            <p>
              In this round, the maximal possible life gain could have been
              achieved even with a limited budget, if you had been provided with
              the right diagnoses. These mistakes will also happen with the
              calibrated version of K.A.L.E. on the easy setting (because
              unfortunately our AI assistant is not perfectly accurate), but
              they will happen less frequently and with less severity than on
              the hard setting.
            </p>
          </IntroScreen>
        );
        break;
      case 10:
        renderStage = (
          <IntroScreen title="Congratulations!"
                       action1={{handler: gotoGame, title: 'Skip Intro'}}
                       action2={{handler: nextStage, title: 'Continue'}}
                       action3={{handler: previousStage, title: 'Back'}}
                       align="justify">
            <p>
              Did you follow the optimal strategy or did you follow your
              intuition? This question will become more relevant as you play the
              game, especially on the hard setting.
            </p>
            <p>
              Try first to play a few rounds on easy mode, check your results,
              and then switch to the hard setting. We recommend to play at least
              10 rounds to really see the difference.
            </p>
            <p>
              Your goal is to do the best possible job given the constraints.
              After each round the accumulated maximal possible score that could
              have been achieved with perfect accuracy will be shown next to your
              score and the score that would have been achieved by playing with
              the optimal strategy.
              You can beat the optimal strategy by chance or by developing an
              intuition about K.A.L.E.'s mistakes. However, on the easy setting
              it should be nearly impossible for the player to beat the optimal
              strategy consistently (there is a certain possibility though,
              see our explanation here: INSERT LINK)
            </p>
            <p>Are you able to save the same amount of life on the hard setting
              as on the easy one? Can you outsmart the optimal strategy?
            </p>
            <p><strong>Good luck,
              the patients' destinies are in your hands!</strong></p>
          </IntroScreen>
        );
        break;
      case 11:
        let playGameProps = { handler: gotoGame, title: 'Play' };
        let replayIntro = { handler: resetStage, title: 'Repeat Intro' };
        renderStage = (
          <IntroScreen title=""
                       middle="The Significance of Calibration"
                       action1={replayIntro}
                       action2={playGameProps}
                       align="justify">
            <h2>You are ready for the real game</h2>
            <p>
              But before you start playing, let us dive deeper into the differences between
              the hard and the easy settings. In both of them the accuracy and patient distributions
              are roughly equal. A useful quantity to compare is the difference between the largest possible
              saved lifespan (for perfect diagnosis) and the save lifespan from the optimal strategy per round.
              We can call this quantity the <em>loss in life</em> .
            </p>
            <p>
              On the hard setting both the average loss in life as well as the variance in it are significantly
              larger than on the easy setting. This difference between the settings
              is <em>entirely due to miscalibration</em>. You should be able to notice how on average it becomes harder
              to make good decisions on the hard setting in the game, although this might become apparent only after
              many rounds.
            </p>
          </IntroScreen>
        );
        break;
      default:
        console.log('Stage of game undecided');
    }
    return renderStage;
  };

  return mainRender(stage);
};

export default Intro;
