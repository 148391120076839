import * as React from "react";

function SvgAvatar3(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(3)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
        <rect
          id="avatar_(3)_svg__c"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(3)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(3)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(3)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(3)_svg__b)">
          <g transform="translate(1 5)">
            <path
              d="M45.833 42.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M20.5 33.869c-2.667.087-6.051.652-10.154 1.693C6.244 36.603 2.962 40.416.5 47L4 58h22v-9.337l-1.25-1.67-4.25-13.124zM31.5 34c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H26v-9.336l1.25-1.67L31.5 34z"
              fill="#F5F5F5"
            />
            <ellipse
              fill="#F5F5F5"
              cx={25.98}
              cy={34.504}
              rx={5.923}
              ry={2.328}
            />
            <path
              d="M31.234 33.26l-1.446-4.507-3.808-.282-3.808.282-1.448 4.507a.629.629 0 00-.032.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fill="#A1684F"
            />
            <path
              d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fillOpacity={0.1}
              fill="#FFF"
            />
            <path
              d="M37 10.584V8.89C37 5.456 34.558 0 26 0S15 5.456 15 8.89v1.694s-1.692.125-1.692 1.27v4.686c0 1.145.423 2.088 1.692 2.088 0 5.724 5.087 12.277 11 12.277s11-6.553 11-12.277c1.27 0 1.692-.943 1.692-2.088v-4.686c0-1.145-1.692-1.27-1.692-1.27z"
              fill="#B7775B"
            />
            <path
              d="M37 10.584c0-2.964 0-8.89-4.23-8.89 1.015 1.25 2.538 4.868 2.538 8.467 0 1.605.112 2.081.403 2.54.29.458.423 1.094.423 2.117 0 2.54-2.06 7.09-5.48 8.467 0-1.2-1.637-2.964-4.654-2.964s-4.654 1.764-4.654 2.964c-3.42-1.376-5.48-5.927-5.48-8.467 0-1.023.132-1.659.423-2.117.29-.459.403-.935.403-2.54 0-3.599 1.523-7.218 2.539-8.468C15 1.693 15 7.62 15 10.583c.846 1.694.846 7.198 0 8.045 0 10.76 7.615 12.7 11 12.7s11-1.94 11-12.7m-11 7.45c-1.636 0-2.962-.53-2.962-1.185 0-.654 1.326-1.185 2.962-1.185 1.636 0 2.962.53 2.962 1.185S27.636 26.08 26 26.08z"
              fill="#463B3B"
            />
            <path
              d="M16.27 39.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L26 48.686C23.885 45.3 20.923 33.87 20.923 32.6c-1.92 1.093-4.654 3.527-4.654 7.197zM35.73 39.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L26 48.686C28.115 45.3 31.077 33.87 31.077 32.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
          <g transform="translate(21 18)">
            <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
            <circle fill="#000" cx={1.633} cy={1} r={1} />
          </g>
          <g transform="translate(29.163 18)">
            <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
            <circle fill="#000" cx={1.633} cy={1} r={1} />
          </g>
        </g>
        <g>
          <mask id="avatar_(3)_svg__d" fill="#fff">
            <use xlinkHref="#avatar_(3)_svg__c" />
          </mask>
          <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(3)_svg__c" />
          <g fillRule="nonzero" mask="url(#avatar_(3)_svg__d)">
            <g transform="translate(1 5)">
              <path
                d="M45.833 42.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
                fill="#CFCFCF"
              />
              <path
                d="M20.5 33.869c-2.667.087-6.051.652-10.154 1.693C6.244 36.603 2.962 40.416.5 47L4 58h22v-9.337l-1.25-1.67-4.25-13.124zM31.5 34c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H26v-9.336l1.25-1.67L31.5 34z"
                fill="#F5F5F5"
              />
              <ellipse
                fill="#F5F5F5"
                cx={25.98}
                cy={34.504}
                rx={5.923}
                ry={2.328}
              />
              <path
                d="M31.234 33.26l-1.446-4.507-3.808-.282-3.808.282-1.448 4.507a.629.629 0 00-.032.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
                fill="#F0C08F"
              />
              <path
                d="M31.234 33.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
                fillOpacity={0.1}
                fill="#FFF"
              />
              <path
                d="M37 10.584V8.89C37 5.456 34.558 0 26 0S15 5.456 15 8.89v1.694s-1.692.125-1.692 1.27v4.686c0 1.145.423 2.088 1.692 2.088 0 5.724 5.087 12.277 11 12.277s11-6.553 11-12.277c1.27 0 1.692-.943 1.692-2.088v-4.686c0-1.145-1.692-1.27-1.692-1.27z"
                fill="#FFCD99"
              />
              <path
                d="M37 10.584c0-2.964 0-8.89-4.23-8.89 1.015 1.25 2.538 4.868 2.538 8.467 0 1.605.112 2.081.403 2.54.29.458.423 1.094.423 2.117 0 2.54-2.06 7.09-5.48 8.467 0-1.2-1.637-2.964-4.654-2.964s-4.654 1.764-4.654 2.964c-3.42-1.376-5.48-5.927-5.48-8.467 0-1.023.132-1.659.423-2.117.29-.459.403-.935.403-2.54 0-3.599 1.523-7.218 2.539-8.468C15 1.693 15 7.62 15 10.583c.846 1.694.846 7.198 0 8.045 0 10.76 7.615 12.7 11 12.7s11-1.94 11-12.7m-11 7.45c-1.636 0-2.962-.53-2.962-1.185 0-.654 1.326-1.185 2.962-1.185 1.636 0 2.962.53 2.962 1.185S27.636 26.08 26 26.08z"
                fill="#463B3B"
              />
              <path
                d="M16.27 39.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L26 48.686C23.885 45.3 20.923 33.87 20.923 32.6c-1.92 1.093-4.654 3.527-4.654 7.197zM35.73 39.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L26 48.686C28.115 45.3 31.077 33.87 31.077 32.6c1.92 1.093 4.654 3.527 4.654 7.197z"
                fill="#E6E6E6"
              />
            </g>
            <g transform="translate(21 18)">
              <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
              <circle fill="#000" cx={1.633} cy={1} r={1} />
            </g>
            <g transform="translate(29.163 18)">
              <ellipse fill="#F5F5F5" cx={1.633} cy={1} rx={1.633} ry={1} />
              <circle fill="#000" cx={1.633} cy={1} r={1} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar3;
