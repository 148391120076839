import React from 'react';
import Header from '../Header/Header';


const right = ({ action1, action2, action3,}) => {
    return (
        <React.Fragment>
            {action1 && (
                <button className="btn-primary" onClick={action1.handler}>
                    {action1.title}
                </button>
            )}
            {/*Yes these are swapped. It's on purpose. Sue me.*/}
            {action3 && <button onClick={action3.handler}>{action3.title}</button>}
            {action2 && <button onClick={action2.handler}>{action2.title}</button>}
        </React.Fragment>
    )
};

const IntroScreen = ({ title, middle, action1, action2, action3, align, children}) => {
  align = align || 'center';

  return (
    <React.Fragment>
      <Header title={title}
              left=""
              middle={<div className="header-msg">{middle}</div>}
              right={right({action1, action2, action3})} />
      <div className={`Intro-${align}`}>
       <section>{children}</section>
      </div>
    </React.Fragment>
  );
};

const WelcomeScreen = ({ title, middle, action1, action2, action3, welcomeImage, align, children}) => {
  align = align || 'center';

  return (
    <React.Fragment>
      <Header title={title}
              left=""
              middle={<div className="header-msg">{middle}</div>}
              right={right({action1, action2, action3})} />
      {welcomeImage}
      <div className={`Intro-${align}`}>
       <section>{children}</section>
      </div>
    </React.Fragment>
  );
};

export {IntroScreen, WelcomeScreen};
