import * as React from "react";

function SvgAvatar19(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(19)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(19)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(19)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(19)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(19)_svg__b)">
          <path
            d="M15.873 11.893c-2.498 3.853-3.168 8.666-2.33 13.142.837 4.475 3.07 8.626 5.96 12.222 2.54-.65 5.262-.068 7.216 1.636 1.633-.757 2.093-2.596 2.247-4.34.21-2.34.419-4.719-.042-7.03-.67-3.394-1.43-4.65-4.927-9.086C20.5 14 19 14.5 15.873 11.893z"
            fill="#463B3B"
          />
          <path
            d="M38.527 11.893c2.498 3.853 3.168 8.666 2.33 13.142-.837 4.475-3.07 8.626-5.96 12.222-2.54-.65-5.262-.068-7.216 1.636-1.633-.757-2.093-2.596-2.247-4.34-.21-2.34-.419-4.719.042-7.03.67-3.394 2.722-6.369 4.927-9.086 2.22-2.758 4.718-5.408 8.124-6.544z"
            fill="#463B3B"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#D9BFB8"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#E6CBC3"
          />
          <path
            d="M27.5 6C25.5 6 14 13.5 15 24.962c1.457 6.029 6.408 10.467 12.293 10.467 6.96 0 12.612-6.206 12.725-13.909C35.5 15.5 29.5 6 27.5 6z"
            fill="#FFE1D9"
          />
          <path
            d="M32.842 29.707c.243.37-2.094 2.463-5.549 2.463-3.455 0-5.792-2.092-5.548-2.463.244-.371 2.601.856 5.548.856s5.306-1.227 5.55-.856z"
            fill="#E6BAAD"
          />
          <g transform="translate(20.293 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g transform="translate(29.826 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <path
            d="M31.49 16.866c-3.396 2.12-10.62-.704-13.99-2.866-.612.437-1.445 2.104-2.5 5-.306.237-.473.737-.5 1.5-.233-.014.247-.486 0-.5.299-.79.65.344.5-.5-.139-.78.188-1.577.083-2.355a8.074 8.074 0 01.986-5.161c-.972-2.093-1.095-4.813-.219-6.947A5.273 5.273 0 0018 6.78 9.042 9.042 0 0117.767 3c.59.92 1.603 1.52 2.644 1.8 1.054.279 2.15.279 3.232.223 1.93-.112 3.848-.405 5.793-.433 1.931-.028 3.917.237 5.629 1.158 1.712.92 3.109 2.595 3.369 4.548 1.246.502 2.068 1.576 2.383 2.902.315 1.325.178 2.706-.069 4.045-.246 1.34-.152 3.404-.248 4.757-.247.014-1.094-.725-1.34-.711-1.85-.907-3.328-2.972-3.164-5.05-1.11.99-2.397 1.702-3.766 2.273a5.06 5.06 0 00.164-2.218c-.287.168-.616.391-.904.572z"
            fill="#463B3B"
          />
          <g>
            <path
              d="M46.833 47.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M21.5 38.869c-2.667.087-6.051.652-10.154 1.693C7.244 41.603 3.962 45.416 1.5 52L5 63h22v-9.337l-1.25-1.67-4.25-13.124zM32.5 39c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H27v-9.336l1.25-1.67L32.5 39z"
              fill="#F5F5F5"
            />
            <path
              d="M17.27 44.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L27 53.686C24.885 50.3 21.923 38.87 21.923 37.6c-1.92 1.093-4.654 3.527-4.654 7.197zM36.73 44.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L27 53.686C29.115 50.3 32.077 38.87 32.077 37.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar19;
