import * as React from "react";

function SvgLungCancer(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        transform="translate(2)"
        stroke="#BAD200"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={16} cy={16} r={11} />
        <path d="M16.087 0h0c1.1 1.375 1.1 3.625 0 5h0M5 5h0c1.714 0 3 1.393 3 3h0M0 15.912h0c1.375-1.1 3.625-1.1 5 0h0M5 27h0c0-1.714 1.393-3 3-3h0M15.912 32h0c-1.1-1.375-1.1-3.625 0-5h0M27 27h0c-1.714 0-3-1.393-3-3h0M32 16.087h0c-1.375 1.1-3.625 1.1-5 0h0M27 5h0c0 1.714-1.393 3-3 3h0M21.125 15.5c.5-.514.875-1.157.875-1.929C22 12.157 20.875 11 19.5 11S17 12.157 17 13.571c0 .772.375 1.415.875 1.929-.5.514-.875 1.157-.875 1.929C17 18.843 18.125 20 19.5 20s2.5-1.157 2.5-2.571c0-.772-.375-1.415-.875-1.929z" />
        <circle cx={12} cy={19} r={2} />
        <path d="M10 14l4-4" />
      </g>
    </svg>
  );
}

export default SvgLungCancer;
