import * as React from "react";

function SvgRefreshIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 21" {...props}>
      <g
        stroke="#FFF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        opacity={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.222 2.011v6.067h-6.066" />
        <path d="M18.684 13.133a9.1 9.1 0 11-2.143-9.464l4.681 4.409" />
      </g>
    </svg>
  );
}

export default SvgRefreshIcon;
