import * as React from "react";

function SvgCold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        stroke="#6CD0B7"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M24 10L34 0M2 28l4 4M4 17l13 13M5 29l5-5M8 20.857L20.857 8 26 13.143 13.143 26" />
      </g>
    </svg>
  );
}

export default SvgCold;
