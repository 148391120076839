import * as React from "react";

function SvgHealthy20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        stroke="#FB816B"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.432 4.585h0a5.009 5.009 0 00-7.311 0l-1 1.095c-.078.079-.309.079-.386 0l-1-1.095c-2.001-2.113-5.233-2.113-7.234 0h0C1.5 6.698 1.5 10.14 3.5 12.255l1.231 1.252 7.08 7.434c.077.079.308.079.385 0l7.08-7.434 1.232-1.253c2-2.113 2-5.556-.077-7.67z" />
        <path d="M7 11.4h2.357l1.572-1.6L12.5 13l1.571-4 1.572 2.4H18" />
      </g>
    </svg>
  );
}

export default SvgHealthy20;
