import {Subject} from 'rxjs';

const getPathFromPublic = (path) => `${process.env.PUBLIC_URL}/${path}`;
const workerPath = getPathFromPublic('pyodide.worker.js');

/**
 * This Pyodide wrapper is to be used in case
 * when we are dealing with Pyodide from a separate worker thread
 * when using Pyodide from from the Main JS Thread
 * use PyodideWrapper file instead
 */

/**
 * PyodideWorkerWrapper: creates one worker instance
 * to be used throughout the code
 * and wraps its onmessage and onerror methods with Observable
 * which then can be used from everywhere
 * it also has getObject method that both send a message to worker
 * and waits from the response from it
 */
class PyodideWorkerWrapper {
  onMessage = new Subject();
  onError = new Subject();
  onRetrieve = new Subject();

  constructor() {
    this.worker = new Worker(workerPath);

    this.worker.onmessage = (e) => {
      try {
        this.onMessage.next(e)
      } catch (e) {
        if (window.confirm(
            "Ups, something went wrong with loading the game. We are very sorry!\n" +
            "Please try reloading the browser to fix this.")) {
          window.location.reload();
        }
      }
      const {results} = e.data;
      if (results === undefined) {
        if (window.confirm(
            "Ups, something went wrong with loading the game. We are very sorry!\n" +
            "Please try reloading the browser to fix this.")) {
          window.location.reload();
        }
      }
      const {name, value} = results;
      if (name) {
        this.onRetrieve.next({ name, value });
      }
    };

    this.worker.onerror = (data) => {
      this.onError.next(data);
    };
  }

  /**
   * Loads the pyodide together with packages
   * @param {Array} packages array of python packages to be loaded on start
   * @returns {Promise}
   */
  loadPackages = (packages) => {
  this.postMessage({
    command: 'loadPyodidePackages',
    arg: {packages},
  });

    return new Promise((resolve, reject) => {
      this.onmessage().subscribe((e) => {
        const {results, error} = e.data;
        if (results.type === 'loaded') {
          resolve(true);
        }
        if (error) {
          reject('Error loading packages');
        }
      });
    });
  };

  /**
   * Runs Python in js
   * @param {string} code string of Python code
   * @returns {any} result converted to js
   */
  runPythonCode = (code) => {
    console.log("Running python code: " + code);
    this.postMessage({
      command: 'runPython',
      arg: code,
    });
  };

  /**
   * Runs Python in js with some parameter passed from js
   * @param {Object} globals Object with globals as keys
   * @param {string} code code to execute
   * @returns {any} result converted to js
   */
  runPythonWithGlobals = (globals, code) => {
    console.log("Running python code with globals: " + code + "; globals: " + globals);
    this.postMessage({
      command: 'runPythonWithGlobals',
      globals: globals,
      arg: code,
    });
  };

  /**
   * Gets some var from python
   * @param {string} name string of Python var
   * @returns {any} result
   */
  getObject = (name) => {
    console.log("Retrieving object from pyodide runtime: " + name);
    this.postMessage({
      command: 'getObject',
      arg: name,
    });
    return new Promise((resolve, reject) => {
      this.onretrieve().subscribe((e) => {
        if (e.name === name) {
          const value = e.value;
          resolve(value);
        }
      });
    });
  };

  postMessage(data) {
    this.worker.postMessage(data);
  }

  onmessage() {
    return this.onMessage;
  }

  onretrieve() {
    return this.onRetrieve;
  }

  terminate() {
    if (this.worker) {
      this.worker.terminate();
    }
  }
}

/**
 * one instance of Web worker is exported
 * and used throughout the App
 */
let worker = new PyodideWorkerWrapper();

window.worker = worker;

export default worker;
