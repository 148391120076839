import * as React from "react";

function SvgBronchitis(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        stroke="#61B6E1"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16 18.544V20.8c0 7.675-2.364 8.24-5.91 8.24-3.308 0-7.09 6.545-7.09-4.29S9.145 5 12.81 5c.945 0 1.772.339 2.245 2.144M20 18.544V20.8c0 7.675 2.364 8.24 5.91 8.24 3.308 0 7.09 6.545 7.09-4.29S26.855 5 23.31 5c-.946 0-1.774.339-2.246 2.144" />
        <path d="M18 0v7.543c0 1.714-.817 3.428-2.217 4.571L11 16M18 8c0 1.622.817 3.243 2.217 4.324L25 16" />
      </g>
    </svg>
  );
}

export default SvgBronchitis;
