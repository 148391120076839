import React from 'react';

const Header = ({ title, subtitle, left, middle, right }) => {
  return (
    <React.Fragment>
      <div className="header container mb-lg">
        {title &&
        <div className="header-start">
          {subtitle &&
          <div className="flex-column txt-center">
            <h2>{title}</h2>
            {subtitle}
          </div>
          }
          {!subtitle &&
          <h2>{title}</h2>
          }
          {left}
        </div>
        }
        {middle}
        <div className="header-btns">
          {/*<div className="delimiter-white mx-md"/>*/}
          {right}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
