import React, {Component} from 'react';
import {Game} from './components/Game';
import ErrorBoundary from './utils/ErrorBoundary';
import Intro from './components/Intro/Intro';
import GamePyodide from './utils/GamePyodide';
import worker from './utils/PyodideWorkerWrapper';
import screenfull from 'screenfull';

const getPathFromPublic = (path) => `${process.env.PUBLIC_URL}/${path}`;

class App extends Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();
    console.log(screenfull);

    this.state = {
      loading: true,
      intro: true,
      error: null,
      fullscreen: false,
      fullwidth: false,
    };

    // see comment in render below
    // this.fullScreen = this.fullScreen.bind(this);
  }

  componentDidMount = async function () {
    /**
     * use the worker instance to load packages in parallel thread
     */
    await worker.loadPackages(
        [getPathFromPublic("pyodide/kale.js"), getPathFromPublic('pyodide/names.js'), "numpy"])

    this.setState({ loading: false });
    console.log('loaded');

    worker.onmessage().subscribe((e) => {
      const { results, error } = e.data;
      if (results) {
        if (results.type === 'loaded') {
          this.setState({ loading: false });
        }
        console.log('pyodideWorker return results: ', results);
      } else if (error) {
        console.log('pyodideWorker error: ', error);
      }
    });
  }

  componentWillUnmount() {
    worker.terminate();
  }

  gotoIntro = () => {
    this.setState({ intro: true });
  };

  gotoGame = () => {
    this.setState({ intro: false });
  };
  // UNUSED: see comment in render below
  // fullScreen = () => {
  //   if (screenfull.isEnabled) {
  //     screenfull.request(this.rootRef.current);
  //     this.setState({ fullscreen: true });
  //   }
  // };
  // shrinkScreen = () => {
  //   if (screenfull.isEnabled) {
  //     screenfull.toggle();
  //     this.setState({ fullscreen: false });
  //   }
  // };
  render() {
    /**
     * Show at start up the intro screen
     * if you click play game button but game is not loaded
     * show loading screen until loading is done
     * then redirect to Game
     */
    let loading = this.state.loading ? (
      <div className="intro-main">
        <div className="loader">
          <div className="loader--dot"/>
          <div className="loader--dot"/>
          <div className="loader--dot"/>
          <div className="loader--dot"/>
          <div className="loader--dot"/>
          <div className="loader--dot"/>
        </div>
        <div className="loader-text">
          <p className="txt-center my-lg">
            <strong>Loading the game.</strong>
            <br/>This might take a while the first time, hang tight!
          </p>
        </div>
      </div>
    ) : (
      <Game provider={GamePyodide} js={false} exitGame={this.gotoIntro} />
    );
    let content = this.state.intro ? <Intro gotoGame={this.gotoGame} /> : loading;
    return (
      <React.Fragment>
        <div className="App-main" id="appmain" ref={this.rootRef}>
          {/*UNUSED BECAUSE UGLY. If we ever fix the full-screen, can be reactivated*/}
          {/*<div className="inapp-buttons">*/}
          {/*  {!this.state.fullscreen && !this.state.fullwidth && <button id="full-screen" className="btn-screen-full" title="Full Screen" onClick={this.fullScreen}></button>}*/}
          {/*  {this.state.fullscreen && <button id="shrink-screen" className="btn-screen-shrink" onClick={this.shrinkScreen} title="Exit Full Screen"></button>}*/}
          {/*</div>*/}
          <ErrorBoundary>{content}</ErrorBoundary>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
