import worker from './PyodideWorkerWrapper';

const {getObject, runPythonCode, runPythonWithGlobals } = worker;

/**
 * Imports all necessary code to start the game
 */
const prepareGame = () => {
  console.log('Importing python packages');
  runPythonCode(`
      from game.game import Game, FakeClassifierPatientProvider
      from game.constants import Disease, TreatmentCost    
      from kale.sampling.fake_clf import DirichletFC
      from kale.transformations import PowerLawSimplexAut, MaxComponentSimplexAut
      import json
      import numpy as np
  `);
};

/**
 * create the game with patients provider
 */
const initGame = (difficulty) => {
  console.log('Initializing Game with difficulty ', difficulty);
  runPythonWithGlobals(
    { difficulty: difficulty },
    `   
        from js import difficulty;
        
        def max_components_transform(arr: np.ndarray, threshold = 0.6):
            arr = arr.copy()
            mask = arr > threshold
            arr[mask] = arr[mask] - 1.26 *(arr[mask] - threshold) ** 1.9
            return arr

        N_CLASSES = 5
        
        ground_truth_distribution = {
            Disease.healthy: 0.2,
            Disease.cold: 0.1,
            Disease.flu: 0.2,
            Disease.bronchitis: 0.27,
            Disease.lung_cancer: 0.23
        }
        
        base_alpha = np.array([
            ground_truth_distribution[i] for i in list(Disease)
        ])
        
        SETTINGS = {
            "hard": {
                "alpha": base_alpha * 1/3,
                "transform": MaxComponentSimplexAut(
                    max_components_transform
                )
            },
            "easy": {
                "alpha":  base_alpha * 1/1.5,
                "transform": None
            }
        }
        params = SETTINGS[difficulty]
        alpha, transform = params["alpha"], params["transform"]
        fake_clf = DirichletFC(N_CLASSES, alpha=alpha, simplex_automorphism=transform)
       
        patient_provider = FakeClassifierPatientProvider(fake_clf, Disease)
        game = Game(patient_provider)
    `
  );
};

/**
 * start new round with the number of patients
 * @param {Number} patientsNumber number of patient
 * @param {Number} maxCost
 */
const startRound = (patientsNumber, maxCost) => {
  //window.patientsNumber = patientsNumber;
  runPythonWithGlobals(
    { patientsNumber: patientsNumber, maxCost: maxCost},
    `
      from js import patientsNumber, maxCost;
      print(f"Starting new round with patientsNumber={patientsNumber} and maxCost={maxCost}")
      game.start_new_round(patientsNumber, max_cost=maxCost)
      print("Round started!")
      `
  );
};

/**
 * gets current round
 * @returns {Promise} to be used as : getRound.then()=>{}
 */
const getRound = () => {
  console.log("Inside getRound in pyodide")
  runPythonCode(`round_json = game.current_round.json()`);
  console.log("Created round_json with pyodide, retrieving it")
  return getObject(`round_json`);
};

/**
 * gets some  dummy var
 * method is used for testing
 * @returns {Promise} to be used as : getDummy.then()=>{}
 */
const getDummy = () => {
  runPythonCode(`dummy = 10`);
  return getObject(`dummy`);
};

/**
 * Gets game summary
 * @returns {Promise} to be used as : getSummary.then()=>{}
 */
const getSummary = () => {
  runPythonCode(`summary = game.get_summary().dict()`);
  return getObject(`summary`);
};

/**
 * Get the patient statistics after each played round
 * @returns {Promise<Array>} where array of dict{...olddict, true_life_gain, treated_disease, maximal_life_gain}
 * to be used as : getPatients.then()=>{}
 */
const getPatients = () => {
  runPythonCode(`
      assert game.current_round is None
      last_round = game.played_rounds[-1]
      patients = last_round.patients
      assigned_treatments = last_round.assigned_treatments
      
      def added_keys(patient):
          d = patient.dict()
          d['true_life_gain'] = patient.true_life_gain(assigned_treatments[patient.uuid])
          d['treated_disease'] = assigned_treatments[patient.uuid]
          d['maximal_life_gain'] = patient.maximal_life_gain()
          return d
      patient_stats_dicts = [added_keys(p) for p in patients]
    `);
  return getObject(`patient_stats_dicts`);
};

/**
 * Get Results Object(cost, max_life_span) after each reound
 * @returns {Promise<Object>} object is the results object
 * to be used as : getResults.then()=>{}
 */
const getResults = () => {
  runPythonCode(`
      assert game.current_round is None
      last_round = game.played_rounds[-1]
      results_json = last_round.results.json()`);
  return getObject(`results_json`);
};

/**
 * Get Scores to be used in Graph
 * @returns {Promise<Object>} object is the results object
 * to be used as : getGraph.then()=>{}
 */
const getGraph = () => {
  runPythonCode(`
      scores = game.get_score_history().dict()
  `);
  return getObject(`scores`);
};

/**
 * reset the game
 */
const resetGame = () => {
  runPythonCode(`game.reset()`);
};

/**
 * returns the dictionary out of Enum TreatmentCost
 * @returns {Object} {healthy: 0, cold: 1, flu: 2, cancer: 3, ...}
 */
const getTreatmentCost = () => {
  runPythonCode(`
      #if we go with: {e.name: e.value for e in TreatmentCost} we don't get duplicate values
      treatmentArray =  {i:TreatmentCost[i].value for i, j in TreatmentCost.__members__.items()}
      print(f"Treatment array: {treatmentArray}")
  `);
  return getObject(`treatmentArray`);
};

/**
 * plays current Round
 * @param {String} treatments stringified json of assigned treatments to each patient
 */
const playRound = (treatments) => {
  runPythonWithGlobals(
    { patientsString: treatments },
    `
      treatments = {${treatments}}
      print(f"Playing current round in python with treatments {treatments}")
      game.play_current_round(treatments)
      print("Current round played!")
  `
  );
};

/**
 * Ends the game
 * @returns {Boolean} whether the Game has ended
 */
const endGame = () => {
  runPythonCode(`
      game.end()
      ended = game.has_ended == True`);
  return getObject('ended');
};

const GamePyodide = {
  prepareGame,
  initGame,
  getRound,
  getTreatmentCost,
  getResults,
  resetGame,
  getSummary,
  getGraph,
  getPatients,
  startRound,
  playRound,
  endGame,
  getDummy,
};

export default GamePyodide;
