import * as React from "react";

function SvgBronchitis20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        stroke="#61B6E1"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11 14.335v1.389c0 4.723-1.455 5.07-3.636 5.07-2.037 0-4.364 4.028-4.364-2.64C3 11.488 6.782 6 9.036 6c.582 0 1.091.208 1.382 1.32M13 14.335v1.389c0 4.723 1.455 5.07 3.636 5.07 2.037 0 4.364 4.028 4.364-2.64C21 11.488 17.218 6 15.036 6c-.581 0-1.09.208-1.381 1.32" />
        <path d="M12 3v4.714a3.88 3.88 0 01-1.267 2.857L8 13M12 8c0 1.014.467 2.027 1.267 2.703L16 13" />
      </g>
    </svg>
  );
}

export default SvgBronchitis20;
