import * as React from "react";

function SvgHealthy(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        stroke="#FB816B"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M32.756 3.73h0a8.862 8.862 0 00-12.795 0l-1.75 1.886c-.135.135-.54.135-.674 0l-1.75-1.887C12.283.09 6.626.09 3.126 3.73h0c-3.502 3.64-3.502 9.57 0 13.209l2.154 2.157 12.39 12.804c.135.135.54.135.674 0l12.39-12.804 2.155-2.157c3.502-3.64 3.502-9.57-.134-13.209z" />
        <path d="M8.5 15.2h4.071l2.715-2.8L18 18l2.714-7 2.715 4.2H27.5" />
      </g>
    </svg>
  );
}

export default SvgHealthy;
