import React from 'react';
import Plot from 'react-plotly.js';
/**
 * @param {Array} props.scoreHistory - statistics of the game
 * @example <SummaryPlot scoreHistory={scoreHistory}  />)
 */

function roundToTwo(num) {
    return +(Math.round(num * 100)/100 );
}

const SummaryPlot = ({ scoreHistory }) => {
  const colors = ['rgb(97, 182, 225)', 'rgb(251, 129, 107)', 'rgb(166, 129, 239)'];
  const num_rounds = scoreHistory[0].value.length - 1
  const player_score = scoreHistory[0].value[num_rounds];
  const best_possible_score = scoreHistory[1].value[num_rounds];
  const optimal_strategy_score = scoreHistory[2].value[num_rounds];
  const average_loss = roundToTwo((best_possible_score - player_score) / num_rounds);
  const average_loss_optimal_strategy = roundToTwo((best_possible_score - optimal_strategy_score) / num_rounds);
  let scoreDescriptionText
  if (player_score > optimal_strategy_score) {
      scoreDescriptionText = "Congratulations, you outperformed the optimal strategy! " +
          "For short games this can easily happen due to randomness but for longer games the optimal strategy " +
          "is (almost) unbeatable on the easy setting. On the hard setting, however, you might have beaten it " +
          `because you implicitly recalibrated the predictions.
          On average you lost ${average_loss} months per round while the optimal strategy resulted in the
          loss of ${average_loss_optimal_strategy} months on average.`
  } else if (player_score === optimal_strategy_score) {
      scoreDescriptionText = "Great, you have followed the optimal strategy! Since K.A.L.E. is not perfect, the score is not" +
          " the same as the best possible score." +
          ` On average you lost ${average_loss} months per round.`
  } else {
      scoreDescriptionText = "Seems like you were beaten by the optimal strategy, wanna try again?" +
          ` On average you lost ${average_loss} months per round while the optimal strategy resulted in the
          loss of ${average_loss_optimal_strategy} months on average.`
  }

  if (scoreHistory) {
    console.log('score History ', scoreHistory);
    const graphPlotlyArray = scoreHistory.map((key, index) => {
      return {
        y: key.value,
        type: 'scatter',
        mode: 'lines+markers',
        name: key.label,
        line: { color: colors[index] },
      };
    });
    return (

      <div className="flex-container">
        <div className="flex-column">
        <Plot
          data={graphPlotlyArray}
          layout={{
            width: 600,
            height: 550,
            legend: { orientation: 'h', yanchor: 'top', y: 1.05, xanchor: 'left', x: 0.1 },
            xaxis: {
              title: {
                text: 'Rounds',
                orientation: 'h',
                font: {
                  family: 'Courier New, monospace',
                  size: 18,
                  color: '#7f7f7f',
                },
              },
            },
            yaxis: {
              title: {
                text: 'Total Months Saved',
                font: {
                  family: 'Courier New, monospace',
                  size: 18,
                  color: '#7f7f7f',
                },
              },
            },
          }}
        />
        </div>
        <div className="flex-column">
            <h3>
                Scores for the last game
            </h3>
            <p>
                {scoreDescriptionText}
            </p>
            <p>
                The maximal possible scores are those that can be achieved with perfect knowledge of the patient's
                diseases, i.e. the scores one could get if the diagnostic predictions were perfect.
            </p>
            <p>
                The optimal strategy focuses on maximizing the expected life span for each round using K.A.L.E.'s
                confidences for computing expectation values. On the hard setting these confidences are not calibrated
                and the "optimal" strategy is in fact no longer optimal.
            </p>
        </div>
      </div>
    );
  }
};

export default SummaryPlot;
