import React from 'react';
import styles from './ColorCodedCard.module.scss';
/**
 * @param {Function} action - click handler for the main button
 * @example <IntroCard  />
 */

const ColorCodedCard = ({ title, color, children }) => {
  return (
    <div className={`${styles.ColorCodedCard} ${styles[color]}`}>
      <h3>{title}</h3>
      {children}
    </div>
  );
};

export default ColorCodedCard;
