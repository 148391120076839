import React, {Component} from 'react';
import PatientCard from './PatientCard/PatientCard';
import TreatmentCard from './TreatmentCard/TreatmentCard';
import SummaryPlot from './SummaryPlot';
import RoundSummary from './RoundSummary';
import SvgIcon from './SvgIcon';
import Header from "./Header/Header";

const DEFAULT_BUDGET = 10;

const INITIAL_STATE = {
  round: 0,
  patientsNumber: 3,
  currentRound: null,
  patients: [],
  treatments: [],
  esl: 0,
  gameSummary: null,
  showModal: false,
};

const RESET_STATE = {
  results: null,
  scoreHistory: null,
  stage: 0,
};

const RESET_ROUND = {
  esl: 0,
};

const diseaseNamesMap = { healthy: 'Healthy', cold: 'Cold', flu: 'Flu', bronchitis: 'Bronchitis', lung_cancer: 'Cancer' };

let gameProvider = {};

/** export provider functions
 * in this namespace
 */
const exportProps = (provider) => {
  gameProvider = provider;
};

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...RESET_STATE,
      ...INITIAL_STATE,
      budget: props.budget || DEFAULT_BUDGET,
      difficulty: 'easy',
      maxCost: props.budget || DEFAULT_BUDGET,
    };

    exportProps(props.provider);
  }

  componentDidMount = () => {
    gameProvider.prepareGame(this.props.mockRoundNumber);
    if (this.props.js) {
      // this means we are playing the intro, the game provider is utils/GameJS
      this.playGame();
    }
  };

  startNewGame = () => {
    this.setState({ stage: 0 });
  };

  playGame = () => {
    gameProvider.initGame(this.state.difficulty);
    this.setState({ ...INITIAL_STATE, ...RESET_STATE, stage: 1 });
    this.startNewRound();
  };

  /**
   * Sets difficulty level
   */
  setDifficulty = (ind) => {
    this.setState({ difficulty: ind });
  };

  /**
   * Starts new Round
   * - reset the stage to 1, and budget to initial
     - calls the gameProvider.startRound
     - calls setNewRoundState() to set round and treatment Info
     - sets the round state
   */
  startNewRound = async () => {
    console.log('inside startNewRound, no round started');
    this.setState({ ...RESET_ROUND, stage: 1, budget: this.props.budget || DEFAULT_BUDGET});
    await gameProvider.startRound(this.state.patientsNumber, this.state.maxCost);
    await this.setNewRoundState();
    if (this.props.js) {
        this.setState({ round: "Intro"});
    }
    else {
      this.setState({round: this.state.round + 1,});
    }
  };


  /**
   * Renders the Info Modal
   * @returns {HtmlElement}
   */
  renderModal = () => {
    return (
      <div id="modalInfo" className="modal">
        <div className="modal-content">
          <div className="icon mb-md"/>
          <p className="my-lg"> {`You have to treat all patients`}</p>
          <button className="btn-secondary" onClick={(e) => this.setState({ showModal: false })}>
            Okay
          </button>
        </div>
      </div>
    );
  };

  /**
   * Forward to the second screen
   * and show some statistics there
   */
  getStatistics = async () => {
    const canplay = this.playCurrentRound();
    /*returns false if not all patients have been played */
    if (!canplay) return false;
    this.setState({ stage: 2 });
    let patients = await gameProvider.getPatients(this.state.patients);
    //copy the avatar from each current patient
    this.state.patients.forEach((item, index) => {
      patients.filter((patient) => patient.uuid === item.uuid)[0].avatar = item.avatar;
    });
    let resultsJson = await gameProvider.getResults(patients);
    resultsJson = JSON.parse(resultsJson);

    let gameSummary = await gameProvider.getSummary();
    const results = resultsJson;
    this.setState({
      results,
      gameSummary,
      patients,
    });
  };

  /**
   * resets round - to initial budget ans esl
   * but also remove the treated property of the patient
   */
  resetRound = async () => {
    const patients = this.state.patients.map((patient) => {
      delete patient.treated;
      return patient;
    });
    this.setState({ ...RESET_ROUND, patients, budget: this.props.budget || DEFAULT_BUDGET });
  };

  /**
   * Ends the game resets the global state
   */
  reset = async () => {
    console.log('inside restGame');

    await gameProvider.resetGame();
    this.startNewGame();
  };

  /**
   * Ends the game resets the global state
   */
  end = async () => {
    let graph = await gameProvider.getGraph();
    console.log('graph is', graph);

    graph = [
      {
        name: 'player_life_gains',
        value: graph.player_life_gains,
        label: "player's scores",
      },
      {
        name: 'maximal_life_gains',
        value: graph.maximal_life_gains,
        label: 'maximal possible scores',
      },
      {
        name: 'optimal_strategy_life_gains',
        value: graph.optimal_strategy_life_gains,
        label: 'optimal strategy scores',
      },
    ];

    this.setState({ scoreHistory: graph });

    const gameEnded = await gameProvider.endGame();
    console.log('gameEnded is ', gameEnded);

    if (gameEnded) {
      this.setState({ ...INITIAL_STATE, stage: 3 });
    }
  };

  /**
   * round to 2 decimals
   * @param {number} num
   */
  roundTo = (num) => {
    if (num !== null) return num.toFixed(2);
  };

  /**
   * show patients
   * @param {HtmlElement} num
   */
  renderPatients = () => {
    return (
      <React.Fragment>
        {/*<h2 className="mb-lg">Patients</h2>*/}
        <div className="container-cards">
          {this.state.patients.map((patient, index) => {
            return (
              <div key={index} onDrop={(e) => this.onDrop(e, patient.uuid, patient)} onDragOver={(ev) => this.onDragOver(ev)}>
                <PatientCard patient={patient} diseaseNamesMap={diseaseNamesMap} />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  shouldBeDraggable = (item) => {
    const { budget } = this.state;
    return item.cost <= budget;
  };

  renderTreatments = () => {
    return (
      <React.Fragment>
        {/*<h2 className="mb-lg">Treatments</h2>*/}
        <div className="shelf">
          <div className="shelf-content">
            {this.state.treatments.map((item, index) => {
              return (
                <div draggable={false} key={index} className="treatment-card draggable" onDragStart={(e) => this.onDragStart(e, item.name)} onDragEnd={(e) => this.onDragEnd(e)}>
                  <TreatmentCard draggable={this.shouldBeDraggable(item)} name={diseaseNamesMap[item.name]} cost={item.cost} image={item.name} />
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  /**
   * Transfer captured object on drop
   */
  onDrop = (ev, uuid, patient) => {
    this.setState({ dropped: true });
    ev.preventDefault();
    let esl;
    let treatment = ev.dataTransfer.getData('treatment');
    if (patient.treated && patient.treated.length) {
      return;
    }
    let patients = this.state.patients.filter((patient) => {
      if (patient.uuid === uuid) {
        esl = patient.confidences[treatment] * patient.treatment_effects[treatment];
        let treated = patient.treated || [];
        treated.push(treatment);
        patient.treated = treated;
      }
      return patient;
    });

    this.statisticsOnDrop(treatment, esl);
    patients = [...new Set(patients)]; //remove duplicates
    this.setState({ patients });
  };

  /**
   * Capture an object on start drag
   */
  onDragStart = (ev, id) => {
    ev.target.style.opacity = '0';
    ev.target.classList.remove('pops');
    ev.dataTransfer.setData('treatment', id);
    /* trigger setDragImage to fix Firefox not whowing correctly fading */
    let img = new Image();
    img.src = ev.target.src;
    let rect = ev.target.getBoundingClientRect();
    let x = ev.clientX - rect.left; //x position within the element.
    let y = ev.clientY - rect.top; //y position within the element.
    ev.dataTransfer.setDragImage(img, x, y);
    this.setState({ dropped: false });
  };

  /**
   * Revert the styles after item is released
   */
  onDragEnd = (ev, id) => {
    ev.target.style.opacity = '1';
    if (this.state.dropped) {
      ev.target.classList.add('pops');
    }
  };

  /**
   * Track the budget on each drag and drop
   */
  statisticsOnDrop = (treatment, saved) => {
    const cost = this.state.treatments.filter((item) => item.name === treatment)[0].cost;
    const budget = this.state.budget - cost;
    const esl = this.state.esl + saved;
    this.setState({ budget, esl });
  };

  /**
   * Helper function - generation random integer between two integers
   */
  randomBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  setNewRoundState = async () => {
    let treatmentsObject = await gameProvider.getTreatmentCost();
    let treatments = [];
    Object.keys(treatmentsObject).forEach((key) => {
      treatments.push({ name: key, cost: treatmentsObject[key] });
    });
    let roundJson = await gameProvider.getRound();
    roundJson = JSON.parse(roundJson);

    let patients = Object.values(roundJson['id_to_patient_dict']);
    //add avatar to each patient - male or female
    patients.forEach((patient, index) => {
      const rndm = this.randomBetween(1, 24);
      patient.avatar = `Avatar_${patient.gender}${rndm}`;
    });

    this.setState({
      currentRound: roundJson,
      patients,
      treatments,
    });
  };

  /**
   * Plays the current round and closes the previous one
   * @returns {boolean} depending or wheter the round is playable or not
   */
  playCurrentRound = () => {
    console.log('inside play current round');
    let patientsString = '';
    const assigned = this.state.patients.filter((patient) => patient.treated && patient.treated.length);
    if (assigned.length < this.state.patientsNumber) {
      this.setState({ showModal: true });

      return false;
    }
    assigned.forEach((filtered) => {
      patientsString =
        patientsString +
        `
          "${filtered.uuid}": Disease.${filtered.treated[0]},`;
    });

    gameProvider.playRound(patientsString);
    return true;
  };

  /**
   * Renders the Graph
   * @returns {HTMLElement}
   */
  renderGraph = () => {
    const { scoreHistory } = this.state;
    return (
      <div className="App-center">
        <div>
          {/*<h2 className="mb-lg stretch">Game results</h2>*/}
          <SummaryPlot scoreHistory={scoreHistory} />
        </div>
      </div>
    );
  };

  /**
   * Renders the Game Summary
   * @returns {HTMLElement}
   */
  renderGameSummary = () => {
    return (
      <React.Fragment>
        <Header title="Game statistics" left="" middle=""
                right={<button className="btn-primary"
                               onClick={this.startNewGame}>Start new game
                       </button>}/>
        {this.renderGraph()}
      </React.Fragment>
    );
  };

  renderRoundSummary = () => {
    if (!this.props.js) {
      return this.renderRoundSummaryPyodide();
    }
    return this.renderRoundSummaryJS();
  };

  renderRoundSummaryJS = () => {
    const { results, gameSummary, patients, budget, esl } = this.state;

    return (
      <RoundSummary explanation={this.props.explanation} results={results} gameSummary={gameSummary} round={"Intro"} patients={patients} budget={budget} esl={esl}>
          <button onClick={this.props.exitGame}>Continue</button>
      </RoundSummary>
    );
  };

  /**
   * Renders the Round Summary
   * @returns {HTMLElement}
   */
  renderRoundSummaryPyodide = () => {
    const { results, gameSummary, round, patients, budget, esl, difficulty } = this.state;
    return (
      <RoundSummary results={results} gameSummary={gameSummary} difficulty={difficulty} round={round} patients={patients} budget={budget} esl={esl}>
          <button onClick={this.end}>End game</button>
          <button className="btn-primary" data-test-id="continue-btn" onClick={this.startNewRound}>
            Next round
          </button>
      </RoundSummary>
    );
  };

  renderBudget = (budget) => {
    if (budget > 900) {
      return '∞';
    }
    return this.roundTo(budget);
  };

  /**
   * Renders the Round Sreen
   * @returns {HTMLElement}
   */
  chooseDifficulty = () => {
    return (
      <React.Fragment>
        <Header title="Choose the Difficulty" left="" middle=""
                right={
                  <div>
                  <button onClick={this.props.exitGame}>Replay Intro</button>
                  <button className="btn-primary" data-test-id="continue-btn" onClick={this.playGame}>
                  Play
                  </button>
                    </div>}/>
        <div className="container">
          <section>
            {/*<h2>Initial game screen</h2>*/}
            <div className="Intro-justify">
              <p>
                First choose a setting for your AI-assistant K.A.L.E. On both
                settings, the accuracy of the predictions is around 73%, which
                means that in each round there is roughly a 60% chance of the
                assistant being wrong at least once.
              </p>
              <p>
                But even if it were 100% accurate, you would still often be
                unable to provide everyone with the best treatment due to the
                budget restrictions. After each round the budget is reset, you
                cannot take the remaining budget over to the next round.
              </p>
              <div className="Intro-justify flex-row difficulty-btns-center">
                {['Easy', 'Hard'].map((item, index) => {
                  const active = this.state.difficulty === item.toLowerCase() ? 'active' : '';
                  return (
                    <div key={index}
                         className={`difficulty ${item.toLowerCase()}_level ${active}`}
                         onClick={(e) => this.setDifficulty(item.toLowerCase())}>
                      <div className="icon"/>
                      {item}
                    </div>
                  );
                })}
              </div>
              <p>
                On the hard setting you should notice that making decisions
                becomes harder and that your mistakes in treatment become more
                severe,  and this despite K.A.L.E. being as accurate as before!.
                As a consequence, the difference between the maximal achievable
                score and the one obtained by the optimal strategy will
                generally be higher as on the easy setting. Here you also have a
                real chance of beating the "optimal" strategy. If you can do so
                consistently: congratulations! You have just re-calibrated
                K.A.L.E. by observing its predictions and the true diseases.
              </p>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  };

  renderButtons = () => {
    return (
      <React.Fragment>
        {this.props.js
          ? <button onClick={(e) => this.props.exitGame(0)}>Reset Intro</button>
          : <button onClick={this.reset}>Reset Game</button>}
        <button className="btn-primary" data-test-id="continue-btn"
                onClick={this.getStatistics}>Continue</button>
      </React.Fragment>
    )
  };

  /**
   * Renders the Round Sreen
   * Renders the Round Sreen
   * @returns {HTMLElement}
   */
  renderRound = () => {
    const { patients, treatments, difficulty } = this.state;
    const { budget, esl, round } = this.state;

    function renderRoundNumber(round) {
      if (typeof round === 'string') {
        return round + " round";
      }
      return "Round " + round;
    }

    const renderResetRound = () => {
      return (
        <div className="btn btn-primary btn-sm tooltip mx-md"
             onClick={this.resetRound}>
          <SvgIcon tag="refresh" svgClass="icon-xs-white"/>
          <span className="tooltiptext">Reset round</span>
        </div>
      );
    }

    const renderStatus = () => {
      return (
        <React.Fragment>
          <div className="stats-box px-sm">
            {/*<div className="mx-md">Status:</div>*/}
            <div className="tooltip">
              <SvgIcon tag="budget" svgClass="icon-xs-white"/>
              <span className="tooltiptext">Remaining Budget</span>
            </div>
            <span>{this.renderBudget(budget)}</span>
            <div className="tooltip">
              <SvgIcon tag="esl" svgClass="icon-xs-white"/>
              <span className="tooltiptext">Expected Life Gain</span>
            </div>
            <span>{this.roundTo(esl)}</span>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Header title={renderRoundNumber(round)}
                subtitle={!this.props.js &&
                          <span className="level">level: {difficulty}</span>}
                left={renderResetRound()}
                middle={renderStatus()}
                right={this.renderButtons() }>
        </Header>
        <div className="App-center">
          <div>
            <section>{treatments && this.renderTreatments()}</section>
            <section>{patients && this.renderPatients()}</section>
          </div>
        </div>
      </React.Fragment>
    );
  };

  /**
   * Decide which Component to Show
   * if stage = 1, show Current Round Screen
   * if stage = 2, show  Round Summar Screen
   * if stage = 3, show  Game Summar Screen
   * @returns {HTMLElement}
   */
  renderMain = () => {
    let renderStage;
    switch (this.state.stage) {
      case 0:
        renderStage = this.chooseDifficulty();
        break;
      case 1:
        renderStage = this.renderRound();
        break;
      case 2:
        renderStage = this.renderRoundSummary();
        break;
      case 3:
        renderStage = this.renderGameSummary();
        break;
      default:
        console.log('Stage of game undecided');
    }
    return renderStage;
  };
  /**
   * main render method
   */
  render() {
    return (
      <React.Fragment>
        {this.renderMain()}
        {this.state.showModal && this.renderModal()}
      </React.Fragment>
    );
  }
}

export { Game, diseaseNamesMap };
