import * as React from "react";

function SvgAvatar6(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(6)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
        <path
          d="M.852 16.49s5.455-2.267 8.125-6.063c.512.566 1.137 2.493 1.137 3.796 3.466-.566 5.625-2.606 8.295-4.816 0 3.173 5.17 6.006 5.966 7.083 0 0 .625-2.833.625-5.44 0-2.833-1.023-5.553-3.352-6.063C20.625 2.04 16.193 0 12.5 0 5.568 0 0 4.93 0 10.993c0 2.664.852 5.497.852 5.497z"
          id="avatar_(6)_svg__c"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(6)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(6)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(6)_svg__a" />
        <g mask="url(#avatar_(6)_svg__b)">
          <g fillRule="nonzero">
            <path
              d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fill="#D9BFB8"
            />
            <path
              d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
              fill="#F0C08F"
            />
            <path
              d="M30.5 6C29.964 13.572 15 19.946 15 24.962c1.457 6.029 6.408 10.467 12.293 10.467 6.96 0 12.612-6.206 12.725-13.909C33.482 20.715 31.423 7.128 30.5 6z"
              fill="#FFCD99"
            />
            <path
              d="M32.842 29.707c.243.37-2.094 2.463-5.549 2.463-3.455 0-5.792-2.092-5.548-2.463.244-.371 2.601.856 5.548.856s5.306-1.227 5.55-.856z"
              fill="#E88579"
            />
            <g transform="translate(20.293 22)">
              <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
              <circle fill="#000" cx={1.733} cy={1.5} r={1} />
            </g>
            <g transform="translate(29.826 22)">
              <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
              <circle fill="#000" cx={1.733} cy={1.5} r={1} />
            </g>
          </g>
          <path
            d="M36.747 11.381S36.687 7 41.177 7C45.666 7 48 10.459 48 15.07c0 4.612-5.447 7.552-5.985 10.665-.54 3.113 1.137 5.073 1.137 5.073s-4.25 1.326-6.225-2.997c-1.975-4.324-.18-16.43-.18-16.43z"
            fill="#5A4B4B"
            fillRule="nonzero"
          />
          <path
            d="M40.524 9.378c-.902-1.382-2.764-1.803-4.146-.902-1.382.902-1.803 2.764-.902 4.146.902 1.382 2.764 1.803 4.146.902 1.382-.902 1.803-2.764.902-4.146z"
            fill="#F5F5F5"
            fillRule="nonzero"
          />
          <path
            d="M18.317 11.381S18.377 7 13.863 7C9.348 7 7 10.459 7 15.07c0 4.612 5.478 7.552 6.02 10.665.542 3.171-1.144 5.073-1.144 5.073s4.274 1.326 6.2-2.997c1.927-4.324.241-16.43.241-16.43z"
            fill="#5A4B4B"
            fillRule="nonzero"
          />
          <path
            d="M14.476 9.378c.902-1.382 2.764-1.803 4.146-.902 1.382.902 1.803 2.764.902 4.146-.902 1.382-2.764 1.803-4.146.902-1.382-.902-1.803-2.764-.902-4.146z"
            fill="#F5F5F5"
            fillRule="nonzero"
          />
          <path
            d="M15.852 22s5.455-2.337 8.125-6.25c.512.583 1.137 2.57 1.137 3.913 3.466-.584 5.625-2.687 8.295-4.965 0 3.271 5.17 6.192 5.966 7.302 0 0 .625-2.921.625-5.608 0-2.921-1.023-5.725-3.352-6.251C35.625 7.103 31.193 5 27.5 5 20.568 5 15 10.082 15 16.333c0 2.746.852 5.667.852 5.667z"
            fill="#463B3B"
            fillRule="nonzero"
          />
          <g transform="translate(15 5)">
            <mask id="avatar_(6)_svg__d" fill="#fff">
              <use xlinkHref="#avatar_(6)_svg__c" />
            </mask>
            <path
              d="M23.239 2.833s-4.034 5.44-4.944 6.97c-.909 1.53 5.682 11.164 5.682 11.164s10.455-7.594 5.341-12.014c-5.113-4.42-6.08-6.12-6.08-6.12z"
              fill="#463B3B"
              fillRule="nonzero"
              mask="url(#avatar_(6)_svg__d)"
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M46.833 47.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M21.5 38.869c-2.667.087-6.051.652-10.154 1.693C7.244 41.603 3.962 45.416 1.5 52L5 63h22v-9.337l-1.25-1.67-4.25-13.124zM32.5 39c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H27v-9.336l1.25-1.67L32.5 39z"
              fill="#F5F5F5"
            />
            <path
              d="M17.27 44.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L27 53.686C24.885 50.3 21.923 38.87 21.923 37.6c-1.92 1.093-4.654 3.527-4.654 7.197zM36.73 44.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L27 53.686C29.115 50.3 32.077 38.87 32.077 37.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar6;
