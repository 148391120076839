import React from 'react';
import SvgIcon from '../SvgIcon';
/**
 * @param {String} title - title of the row to render
 * @param {String} ite,m - path of the icon to render
 */

const IconTreatment = ({ item, title }) => {
  const icon = `${item}IconSmall`;

  return (
    <React.Fragment>
      <SvgIcon tag={icon} />
      {title}
    </React.Fragment>
  );
};

export default IconTreatment;
