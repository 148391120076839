import React from 'react';
import styles from './TreatmentCard.module.scss';
import Icon from '../Icon';

/**
 * @param {String} name - name of treatment
 * @param {String} cost - cost of the medicine
 * @param {String} image
 * @param {boolean} draggable
 * @example <TreatmentCard cost={cost} name={treatment}  />)
 */

const TreatmentCard = ({ name, cost, image, draggable, forceColor=false, suppressNote=false }) => {
  const icon = draggable || forceColor ? `${image}` : `${image}Gray`;
  const bigStyle = draggable ? styles.bigDraggable : styles.big
  const note = suppressNote ? null :
      <div className={styles.note}>
        <span className={styles.name}>{name} </span>
        <span className={styles.cost}> {cost}€</span>
      </div>

  return (
    <React.Fragment>
      <div>
        <Icon item={icon} classname={styles.small} />
        <Icon item={icon} classname={bigStyle} draggable={draggable} />
        <Icon item={icon} classname={styles.small} />
      </div>
      {note}
    </React.Fragment>
  );
};

export default TreatmentCard;
