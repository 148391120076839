import React from 'react';
import styles from './PatientCard.module.scss';
import IconTreatement from './IconTreatement';
import SvgIcon from '../SvgIcon';

const roundTo = (num) => {
  if (num !== null) return num.toFixed(2);
};

/**
 * @param {Object} patient - patient to render in the Card
 * @param diseaseNamesMap
 * @example <PatientCard patient={patient}  />)
 */
const PatientCard = ({ patient, diseaseNamesMap }) => {
  const treatmentKeys = Object.keys(patient.confidences);
  return (
    <div className={styles.cardPatient + (patient.treated && patient.treated.length ? ' not-droppable' : '')}>
      <div className={styles.cardHeader}>
        <h3>
          <span className={styles.avatar} alt={patient.name}>
            <SvgIcon tag={patient.avatar} className="" />
          </span>
          {patient.name}
        </h3>
      </div>
      <table className={styles.styledTable}>
        <thead>
          <tr>
            <th></th>
            <th>Conf</th>
            <th>LG</th>
            <th>ELG</th>
          </tr>
        </thead>
        <tbody>
          {treatmentKeys.map((item, index) => {
            const probability = patient.confidences[item];
            const savedLifespan = patient.treatment_effects[item];
            return (
              <tr key={index} className={patient.treated && patient.treated.includes(item) ? `highlight-treatment ${item}_class` : `${item}_class`}>
                <td className={styles.rowTitle}>
                  <IconTreatement item={item} title={diseaseNamesMap[item]} />
                </td>
                <td>{roundTo(probability)}</td>
                <td>{savedLifespan.toFixed(1)}</td>
                <td>{roundTo(probability * savedLifespan)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PatientCard;
