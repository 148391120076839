import React from 'react';
/* import * as icons from '../images/icons/'; */

import bronchitis from '../images/svg/treatments/bronchitis.svg';
import bronchitisGray from '../images/svg/treatments/bronchitis-gray.svg';

import cold from '../images/svg/treatments/cold.svg';
import coldGray from '../images/svg/treatments/cold-gray.svg';

import flu from '../images/svg/treatments/flu.svg';
import fluGray from '../images/svg/treatments/flu-gray.svg';

import healthy from '../images/svg/treatments/healthy.svg';
import healthyGray from '../images/svg/treatments/healthy-gray.svg';

import lung_cancer from '../images/svg/treatments/lung_cancer.svg';
import lung_cancerGray from '../images/svg/treatments/lung_cancer-gray.svg';

const Icon = ({ item, classname, draggable=false }) => {
  let icons = { bronchitis, bronchitisGray, cold, coldGray, flu, fluGray, healthy, healthyGray, lung_cancer, lung_cancerGray };

  const icon = icons[`${item}`];

  return <img alt={icon} src={icon} className={classname} draggable={draggable} />;
};

export default Icon;
