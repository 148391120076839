import * as React from "react";

function SvgBronchitis(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 78 106" {...props}>
      <defs>
        <rect
          id="bronchitis_svg__c"
          x={0}
          y={0}
          width={40}
          height={24}
          rx={12}
        />
        <path
          d="M39 71h23c12 0 16-19.023 16-32C78 17.46 60.54 0 39 0S0 17.46 0 39c0 13.061 4.025 32 16.5 32H39z"
          id="bronchitis_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 35)">
          <mask id="bronchitis_svg__b" fill="#fff">
            <use xlinkHref="#bronchitis_svg__a" />
          </mask>
          <use
            fill="#61B6E1"
            fillRule="nonzero"
            opacity={0.8}
            xlinkHref="#bronchitis_svg__a"
          />
          <path
            d="M77.75 52.462S60.89 53 36.89 53s-37.14-.538-37.14-.538V18s16.14.538 37.14.538S77.75 18 77.75 18v34.462z"
            fill="#61B6E1"
            fillRule="nonzero"
            mask="url(#bronchitis_svg__b)"
          />
          <path
            d="M23.75 18.432V53h15.048c23.467 0 39.952-.54 39.952-.54V18s-19.419.54-39.952.54c-5.427 0-10.501-.054-15.048-.108z"
            fillOpacity={0.3}
            fill="#FFF"
            fillRule="nonzero"
            mask="url(#bronchitis_svg__b)"
          />
        </g>
        <path fill="#7FC4E6" d="M28 24h21v15H28z" />
        <g transform="translate(18)">
          <mask id="bronchitis_svg__d" fill="#fff">
            <use xlinkHref="#bronchitis_svg__c" />
          </mask>
          <use
            fill="#404D66"
            fillRule="nonzero"
            opacity={0.5}
            xlinkHref="#bronchitis_svg__c"
          />
          <path
            d="M0 10v8.4C0 21.493 1.628 24 3.636 24h32.728C38.372 24 40 21.493 40 18.4V10H0z"
            fill="#CDD1D7"
            fillRule="nonzero"
            mask="url(#bronchitis_svg__d)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgBronchitis;
