import * as React from "react";

function SvgAvatar9(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(9)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(9)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(9)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(9)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(9)_svg__b)">
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#D9BFB8"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#F0C08F"
          />
          <path
            d="M27.5 2C26.964 9.572 15 19.946 15 24.962c1.457 6.029 6.408 10.467 12.293 10.467 6.96 0 12.612-6.206 12.725-13.909C33.482 20.715 28.423 3.128 27.5 2z"
            fill="#FFCD99"
          />
          <path
            d="M32.842 29.707c.243.37-2.094 2.463-5.549 2.463-3.455 0-5.792-2.092-5.548-2.463.244-.371 2.601.856 5.548.856s5.306-1.227 5.55-.856z"
            fill="#E88579"
          />
          <g transform="translate(20.293 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g transform="translate(29.826 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g>
            <path
              d="M46.833 47.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M21.5 38.869c-2.667.087-6.051.652-10.154 1.693C7.244 41.603 3.962 45.416 1.5 52L5 63h22v-9.337l-1.25-1.67-4.25-13.124zM32.5 39c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H27v-9.336l1.25-1.67L32.5 39z"
              fill="#F5F5F5"
            />
            <path
              d="M17.27 44.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L27 53.686C24.885 50.3 21.923 38.87 21.923 37.6c-1.92 1.093-4.654 3.527-4.654 7.197zM36.73 44.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L27 53.686C29.115 50.3 32.077 38.87 32.077 37.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
        </g>
        <g fillRule="nonzero">
          <path
            d="M24.515 10.236c1.885 1.097 4.51 1.002 6.303-.217 1.781-1.22 2.604-3.488 1.969-5.436-.636-1.947-2.699-3.431-4.928-3.573-2.23-.133-4.49 1.096-5.407 2.94-.636 1.266-.625 2.826.187 4.008.646.936.844 1.673 1.876 2.278z"
            fill="#463B3B"
          />
          <path
            d="M39.33 25.64c.511-1.24-.014-2.636-.885-3.728-.87-1.082-2.059-1.96-3.081-2.94-1.023-.99-1.977-2.162-2.018-3.469-.843 2.433-3.787 3.897-6.647 4.618-2.86.72-5.929.957-8.582 2.106-.843.372-1.658.833-2.211 1.487-.567.653-.857 1.509-.608 2.286-1.064-3.244-1.797-6.803-.885-10.08.899-3.277 3.29-6.352 6.827-7.873C25.704 6.133 31.3 7 35.433 9.377c2.031 1.17 4.228 2.128 5.168 4.065.94 1.937 1.064 4.1.732 6.172-.331 2.072-1.243 4.032-2.003 6.026z"
            fill="#5A4B4B"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar9;
