import * as React from "react";

function SvgAvatar13(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" {...props}>
      <defs>
        <rect
          id="avatar_(13)_svg__a"
          x={0}
          y={0}
          width={55}
          height={55}
          rx={15}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="avatar_(13)_svg__b" fill="#fff">
          <use xlinkHref="#avatar_(13)_svg__a" />
        </mask>
        <use fill="#6CD0B7" opacity={0.49} xlinkHref="#avatar_(13)_svg__a" />
        <g fillRule="nonzero" mask="url(#avatar_(13)_svg__b)">
          <g fill="#463B3B">
            <path d="M17.014 14.513c-1.628 1.822-2.417 4.364-2.582 6.879-.153 2.515.267 5.03.788 7.49.166.761.331 1.523.331 2.297 0 .775-.203 1.577-.7 2.148.878-.285 1.565-.856 2.176-1.59a7.831 7.831 0 00.153 4.798 6.086 6.086 0 011.272-2.718c-.166 1.454.42 2.922 1.285 4.064s1.984 2.026 3.09 2.882c1.044.802 2.812-.612 3.741-1.563.929-.952 1.463-2.284 1.68-3.643.228-1.36.165-2.746.037-4.12-.343-3.942-1.132-7.816-1.933-11.69-.382-1.876-.878-3.942-2.38-5.002-.877-.64-1.996-.816-3.052-.721-1.03.109-3.18-.326-3.906.49z" />
            <path d="M37.783 14.513c1.627 1.822 2.416 4.364 2.581 6.879.166 2.515-.267 5.03-.788 7.49-.166.761-.33 1.523-.33 2.297 0 .775.203 1.577.699 2.148-.878-.285-1.565-.856-2.175-1.59a7.833 7.833 0 01-.153 4.798 6.087 6.087 0 00-1.272-2.718c.166 1.454-.42 2.922-1.284 4.064-.865 1.142-1.984 2.026-3.09 2.882-1.043.802-2.811-.612-3.74-1.563-.928-.952-1.462-2.284-1.678-3.643-.23-1.36-.166-2.746-.038-4.12.343-3.942 1.132-7.816 1.933-11.69.381-1.876.877-3.942 2.378-5.002.877-.64 1.997-.816 3.052-.721 1.03.109 3.18-.326 3.905.49z" />
          </g>
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-.74.055 3.067.227 1.448 4.507h-.002a.571.571 0 01.035.188c0 .853-1.98 1.556-4.548 1.672.242.01.488.018.74.018 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#D9BFB8"
          />
          <path
            d="M32.234 38.26h.002l-1.448-4.507-3.808-.282-3.808.282-1.448 4.507h.002a.571.571 0 00-.034.188c0 .934 2.368 1.69 5.288 1.69 2.92 0 5.289-.756 5.289-1.69a.57.57 0 00-.035-.188z"
            fill="#E6CBC3"
          />
          <path
            d="M27.5 6C25.5 6 15 19.946 15 24.962c1.457 6.029 6.408 10.467 12.293 10.467 6.96 0 12.612-6.206 12.725-13.909C35.5 15.5 29.5 6 27.5 6z"
            fill="#FFE1D9"
          />
          <path
            d="M32.842 29.707c.243.37-2.094 2.463-5.549 2.463-3.455 0-5.792-2.092-5.548-2.463.244-.371 2.601.856 5.548.856s5.306-1.227 5.55-.856z"
            fill="#E6BAAD"
          />
          <g transform="translate(20.293 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <g transform="translate(29.826 22)">
            <ellipse fill="#F5F5F5" cx={1.733} cy={1.5} rx={1.733} ry={1.5} />
            <circle fill="#000" cx={1.733} cy={1.5} r={1} />
          </g>
          <path
            d="M42 16.568c-1.124-.563-1.849-1.624-2.344-2.706-.496-1.083-.81-2.24-1.378-3.29-.954-1.794-2.598-3.227-4.495-4.192C31.886 5.425 29.651 5.17 27.5 5c-2.163.17-4.386.414-6.283 1.38-1.897.955-3.54 2.398-4.495 4.192-.568 1.06-.882 2.207-1.378 3.29-.495 1.082-1.22 2.143-2.344 2.706.46.106.834.159 1.28.021a11.569 11.569 0 00.242 9.615c.06-.86.303-1.698.713-2.473.532 1.168 1.317 2.282 2.2 3.269-.315-1.581.58-3.067.555-4.68-.036-2.675 1.777-5.296 4.423-6.41-.145.36-.157.732-.435 1.03 2.308-.266 4.422-1.699 5.353-3.652.048-.106.181-.466.181-.573 0 .117.133.478.181.573.943 1.953 3.045 3.397 5.353 3.651-.278-.286-.29-.668-.435-1.03 2.646 1.115 4.447 3.736 4.423 6.41-.024 1.614.87 3.1.556 4.681.87-.976 1.655-2.101 2.199-3.269a6.67 6.67 0 01.713 2.473 11.568 11.568 0 00.241-9.615c.423.138.798.074 1.257-.021z"
            fill="#5A4B4B"
          />
          <g>
            <path
              d="M46.833 47.76c-1.937-9.738-16.463-8.468-19.853-8.468-3.39 0-17.916-1.27-19.852 8.467-1.937 9.737 7.747 13.124 7.747 13.124h24.21s9.684-3.387 7.748-13.124z"
              fill="#CFCFCF"
            />
            <path
              d="M21.5 38.869c-2.667.087-6.051.652-10.154 1.693C7.244 41.603 3.962 45.416 1.5 52L5 63h22v-9.337l-1.25-1.67-4.25-13.124zM32.5 39c2.667.088 6.051.652 10.154 1.693 4.102 1.042 7.384 4.854 9.846 11.438l-3.5 11H27v-9.336l1.25-1.67L32.5 39z"
              fill="#F5F5F5"
            />
            <path
              d="M17.27 44.796c.845-.424 4.23-1.694 4.23-1.694l-.423 3.81L27 53.686C24.885 50.3 21.923 38.87 21.923 37.6c-1.92 1.093-4.654 3.527-4.654 7.197zM36.73 44.796c-.845-.424-4.23-1.694-4.23-1.694l.423 3.81L27 53.686C29.115 50.3 32.077 38.87 32.077 37.6c1.92 1.093 4.654 3.527 4.654 7.197z"
              fill="#E6E6E6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgAvatar13;
