import React, { Component } from 'react';
import Avatar_female1 from '../images/avatars/js/female/Avatar (1)';
import Avatar_female2 from '../images/avatars/js/female/Avatar (2)';
import Avatar_female3 from '../images/avatars/js/female/Avatar (3)';
import Avatar_female4 from '../images/avatars/js/female/Avatar (4)';
import Avatar_female5 from '../images/avatars/js/female/Avatar (5)';
import Avatar_female6 from '../images/avatars/js/female/Avatar (6)';
import Avatar_female7 from '../images/avatars/js/female/Avatar (7)';
import Avatar_female8 from '../images/avatars/js/female/Avatar (8)';
import Avatar_female9 from '../images/avatars/js/female/Avatar (9)';
import Avatar_female10 from '../images/avatars/js/female/Avatar (10)';
import Avatar_female11 from '../images/avatars/js/female/Avatar (11)';
import Avatar_female12 from '../images/avatars/js/female/Avatar (12)';
import Avatar_female13 from '../images/avatars/js/female/Avatar (13)';
import Avatar_female14 from '../images/avatars/js/female/Avatar (14)';
import Avatar_female15 from '../images/avatars/js/female/Avatar (15)';
import Avatar_female16 from '../images/avatars/js/female/Avatar (16)';
import Avatar_female17 from '../images/avatars/js/female/Avatar (17)';
import Avatar_female18 from '../images/avatars/js/female/Avatar (18)';
import Avatar_female19 from '../images/avatars/js/female/Avatar (19)';
import Avatar_female20 from '../images/avatars/js/female/Avatar (20)';
import Avatar_female21 from '../images/avatars/js/female/Avatar (21)';
import Avatar_female22 from '../images/avatars/js/female/Avatar (22)';
import Avatar_female23 from '../images/avatars/js/female/Avatar (23)';
import Avatar_female24 from '../images/avatars/js/female/Avatar (24)';
import Avatar_male1 from '../images/avatars/js/male/Avatar (1)';
import Avatar_male2 from '../images/avatars/js/male/Avatar (2)';
import Avatar_male3 from '../images/avatars/js/male/Avatar (3)';
import Avatar_male4 from '../images/avatars/js/male/Avatar (4)';
import Avatar_male5 from '../images/avatars/js/male/Avatar (5)';
import Avatar_male6 from '../images/avatars/js/male/Avatar (6)';
import Avatar_male7 from '../images/avatars/js/male/Avatar (7)';
import Avatar_male8 from '../images/avatars/js/male/Avatar (8)';
import Avatar_male9 from '../images/avatars/js/male/Avatar (9)';
import Avatar_male10 from '../images/avatars/js/male/Avatar (10)';
import Avatar_male11 from '../images/avatars/js/male/Avatar (11)';
import Avatar_male12 from '../images/avatars/js/male/Avatar (12)';
import Avatar_male13 from '../images/avatars/js/male/Avatar (13)';
import Avatar_male14 from '../images/avatars/js/male/Avatar (14)';
import Avatar_male15 from '../images/avatars/js/male/Avatar (15)';
import Avatar_male16 from '../images/avatars/js/male/Avatar (16)';
import Avatar_male17 from '../images/avatars/js/male/Avatar (17)';
import Avatar_male18 from '../images/avatars/js/male/Avatar (18)';
import Avatar_male19 from '../images/avatars/js/male/Avatar (19)';
import Avatar_male20 from '../images/avatars/js/male/Avatar (20)';
import Avatar_male21 from '../images/avatars/js/male/Avatar (21)';
import Avatar_male22 from '../images/avatars/js/male/Avatar (22)';
import Avatar_male23 from '../images/avatars/js/male/Avatar (23)';
import Avatar_male24 from '../images/avatars/js/male/Avatar (24)';

import bronchitis from '../images/js/treatments/Bronchitis';
import bronchitisGray from '../images/js/treatments/BronchitisGray';
import cold from '../images/js/treatments/Cold';
import coldGray from '../images/js/treatments/ColdGray';
import flu from '../images/js/treatments/Flu';
import fluGray from '../images/js/treatments/FluGray';
import healthy from '../images/js/treatments/Healthy';
import healthyGray from '../images/js/treatments/Healthy';
import lung_cancer from '../images/js/treatments/LungCancer';
import lung_cancerGray from '../images/js/treatments/LungCancerGray';

import bronchitisIcon from '../images/js/icons/Bronchitis';
import bronchitisIconSmall from '../images/js/icons/Bronchitis20';
import lung_cancerIcon from '../images/js/icons/LungCancer';
import lung_cancerIconSmall from '../images/js/icons/LungCancer';
import coldIcon from '../images/js/icons/Cold';
import coldIconSmall from '../images/js/icons/Cold20';
import fluIcon from '../images/js/icons/Flu';
import fluIconSmall from '../images/js/icons/Flu20';
import healthyIcon from '../images/js/icons/Healthy';
import healthyIconSmall from '../images/js/icons/Healthy20';

import budget from '../images/js/icons/Budget';
import cost from '../images/js/icons/Cost';
import esl from '../images/js/icons/Esl';
import refresh from '../images/js/icons/RefreshIcon';

import true_life_gain from '../images/js/icons/TrueLifeGain20';
import true_life_gain_white from '../images/js/icons/TrueLifeGain';
import maximal_life_gain from '../images/js/icons/MaximalLifeGain20';
import disease from '../images/js/icons/Disease20';
import treated_disease from '../images/js/icons/TreatedDisease20';

import level_easy from '../images/js/icons/LevelEasy';
import level_easy_active from '../images/js/icons/LevelEasyActive';
import level_medium from '../images/js/icons/LevelMedium';
import level_medium_active from '../images/js/icons/LevelMediumActive';
import level_hard from '../images/js/icons/LevelHard';
import level_hard_active from '../images/js/icons/LevelHardActive';

/**
 * @classdesc SvgIcon - Wrapper for generic SVgIcon
 * @param {string} props.tag - which icon to show
 * @param {string} props.svgClass - css classes
 * @class
 * @hideconstructor
 * @example
 *  <SvgIcon tag="menu" svgClass="svg-icon" />
 */
class SvgIcon extends Component {
  components = {
    Avatar_female1,
    Avatar_female2,
    Avatar_female3,
    Avatar_female4,
    Avatar_female5,
    Avatar_female6,
    Avatar_female7,
    Avatar_female8,
    Avatar_female9,
    Avatar_female10,
    Avatar_female11,
    Avatar_female12,
    Avatar_female13,
    Avatar_female14,
    Avatar_female15,
    Avatar_female16,
    Avatar_female17,
    Avatar_female18,
    Avatar_female19,
    Avatar_female20,
    Avatar_female21,
    Avatar_female22,
    Avatar_female23,
    Avatar_female24,
    Avatar_male1,
    Avatar_male2,
    Avatar_male3,
    Avatar_male4,
    Avatar_male5,
    Avatar_male6,
    Avatar_male7,
    Avatar_male8,
    Avatar_male9,
    Avatar_male10,
    Avatar_male11,
    Avatar_male12,
    Avatar_male13,
    Avatar_male14,
    Avatar_male15,
    Avatar_male16,
    Avatar_male17,
    Avatar_male18,
    Avatar_male19,
    Avatar_male20,
    Avatar_male21,
    Avatar_male22,
    Avatar_male23,
    Avatar_male24,
    bronchitis,
    bronchitisGray,
    flu,
    fluGray,
    cold,
    coldGray,
    healthy,
    healthyGray,
    lung_cancer,
    lung_cancerGray,
    bronchitisIcon,
    bronchitisIconSmall,
    lung_cancerIcon,
    lung_cancerIconSmall,
    coldIcon,
    coldIconSmall,
    fluIcon,
    fluIconSmall,
    healthyIcon,
    healthyIconSmall,
    budget,
    cost,
    esl,
    refresh,
    true_life_gain,
    true_life_gain_white,
    maximal_life_gain,
    disease,
    treated_disease,
    level_easy,
    level_easy_active,
    level_medium,
    level_medium_active,
    level_hard,
    level_hard_active,
  };
  render() {
    const TagName = this.components[this.props.tag || 'info'];
    const svgClass = this.props.svgClass || 'svg-icon icon-md';
    return <TagName className={svgClass} />;
    //return <img alt={TagName} src={this.props.tag} className={svgClass} />;
  }
}
export default SvgIcon;
