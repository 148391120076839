import * as React from "react";

function SvgColdGray(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 72 106" {...props}>
      <g transform="translate(.25)" fillRule="nonzero" fill="none">
        <path
          d="M66.006 32.2h-5.462V24H11.39v8.2H5.93a5.464 5.464 0 00-5.462 5.467v57.4C.467 101.105 5.357 106 11.39 106h49.154c6.033 0 10.923-4.895 10.923-10.933v-57.4a5.464 5.464 0 00-5.461-5.467z"
          fill="#E0E5E8"
        />
        <path
          d="M71.467 83.462S56.12 84 34.274 84C12.428 84 .467 83.462.467 83.462V49s14.692.538 33.807.538c19.116 0 37.193-.538 37.193-.538v34.462z"
          fill="#9CA3B0"
        />
        <path
          d="M20.467 49.432V84h13.954c21.76 0 37.046-.54 37.046-.54V49s-18.006.54-37.046.54c-5.032 0-9.738-.054-13.954-.108z"
          fill="#B9BEC7"
        />
        <rect
          fill="#404D66"
          opacity={0.5}
          x={5.467}
          width={60}
          height={24}
          rx={6}
        />
        <path
          d="M5.467 10v8.4c0 3.093 2.442 5.6 5.455 5.6h49.09c3.013 0 5.455-2.507 5.455-5.6V10h-60z"
          fill="#FFF"
          opacity={0.5}
        />
      </g>
    </svg>
  );
}

export default SvgColdGray;
