import * as React from "react";

function SvgLevelHard(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        fill="#FB816B"
        stroke="#FB816B"
        strokeWidth={2}
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M26.5 7.5v17M18.5 15.5v9M10.5 18.5v6" />
      </g>
    </svg>
  );
}

export default SvgLevelHard;
