const GameJSMock = {
  treatments: {
    healthy: 0,
    cold: 1,
    flu: 3,
    bronchitis: 5,
    lung_cancer: 8,
  },
  rounds: {
    1: {
      uuid: '1',
      id_to_patient_dict: {
        '11': {
          uuid: '11',
          name: 'Marilyn Dumas',
          gender: 'female',
          disease: 'bronchitis',
          treatment_effects: {
            healthy: 0.0,
            cold: 3.0,
            flu: 5.0,
            bronchitis: 9.0,
            lung_cancer: 55.0,
          },
          confidences: {
            healthy: 0.84,
            cold: 0,
            flu: 0.05,
            bronchitis: 0.1,
            lung_cancer: 0.01,
          },
        },
        '12': {
          uuid: '12',
          name: 'Carla Binder',
          gender: 'female',
          disease: 'lung_cancer',
          treatment_effects: {
            healthy: 0.0,
            cold: 17.0,
            flu: 38.0,
            bronchitis: 12.0,
            lung_cancer: 70.0,
          },
          confidences: {
            healthy: 0.05,
            cold: 0.1,
            flu: 0.05,
            bronchitis: 0.5,
            lung_cancer: 0.3,
          },
        },
        '13': {
          uuid: '13',
          name: 'Stephen Jarrett',
          gender: 'male',
          disease: 'cold',
          treatment_effects: {
            healthy: 0.0,
            cold: 13.0,
            flu: 15.0,
            bronchitis: 12.0,
            lung_cancer: 26.0,
          },
          confidences: {
            healthy: 0.25,
            cold: 0.3,
            flu: 0.5,
            bronchitis: 0.05,
            lung_cancer: 0.0,
          },
        },
      },
    },
    2: {
      uuid: '2',
      id_to_patient_dict: {
        '11': {
          uuid: '11',
          name: 'Susan Bottery',
          gender: 'female',
          disease: 'bronchitis',
          treatment_effects: {
            healthy: 0.0,
            cold: 3.0,
            flu: 5.0,
            bronchitis: 9.0,
            lung_cancer: 55.0,
          },
          confidences: {
            healthy: 0.84,
            cold: 0,
            flu: 0.05,
            bronchitis: 0.1,
            lung_cancer: 0.01,
          },
        },
        '12': {
          uuid: '12',
          name: 'Wilson Tocker',
          gender: 'male',
          disease: 'lung_cancer',
          treatment_effects: {
            healthy: 0.0,
            cold: 7.0,
            flu: 15.0,
            bronchitis: 12.0,
            lung_cancer: 70.0,
          },
          confidences: {
            healthy: 0.05,
            cold: 0.1,
            flu: 0.05,
            bronchitis: 0.5,
            lung_cancer: 0.3,
          },
        },
        '13': {
          uuid: '13',
          name: 'Roberta Canterville',
          gender: 'female',
          disease: 'healthy',
          treatment_effects: {
            healthy: 0.0,
            cold: 13.0,
            flu: 7.0,
            bronchitis: 15.0,
            lung_cancer: 35.0,
          },
          confidences: {
            healthy: 0.45,
            cold: 0.2,
            flu: 0.3,
            bronchitis: 0.05,
            lung_cancer: 0.0,
          },
        },
      },
    },
  },
};

export default GameJSMock;
