import * as React from "react";

function SvgFlu(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
      <g
        stroke="#A681EF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23.797 1.717L8.675 16.36l-.882 4.27L2.5 27.584 6.028 31l7.183-5.124 4.411-.855 15.122-14.64c1.008-.977 1.008-2.441 0-3.417L27.45 1.839a2.538 2.538 0 00-3.654-.122z" />
        <path d="M22.259 10.088l1.754 1.74-7.015 6.963-1.754-1.74zM27.5 7l-2 2M6.5 24l3.5 4" />
      </g>
    </svg>
  );
}

export default SvgFlu;
