import GameJSMock from './GameJSMock';

let aggregate = {
  roundSummary: {},
  gameSummary: {
    num_rounds: 0,
    maximal_total_life_gain: 0,
    optimal_strategy_total_life_gain: 0,
    player_total_life_gain: 0,
  },
};

/**
 * create the game with patients provider
 */
const initGame = () => {};

/**
 * start new round with the number of patients
 * @param {Number}  #num # of patient
 *
 */
const startRound = () => {};

// re-initiated in prepareGame if we run out of bounds
let roundNumber = 1

/**
 * gets current round
 */
const getRound = () => {
  console.log("Retrieving mock round " + roundNumber)
  const round = GameJSMock.rounds[roundNumber];
  return JSON.stringify(round);
};

/**
 * Game summary
 */
const getSummary = () => {
  let round = aggregate.roundSummary;
  const maximal_total_life_gain = round.maximal_life_gain;

  // we only have two intro rounds, the magic numbers are hardcoded results of the best strategy
  // based on the hardcoded patients in GameJSMock
  const optimal_strategy_total_life_gain = roundNumber === 1 ? 79 : 70 ;
  const player_total_life_gain = round.true_life_gain;
  const summary = {
    num_rounds: "Intro",
    maximal_total_life_gain,
    optimal_strategy_total_life_gain,
    player_total_life_gain,
  };
  aggregate.gameSummary = summary;
  return summary;
};

/**
 * Get the patient statistics after each played round
 */
const getPatients = (pat) => {
  let patients;
  patients = pat.map((pat) => {
    const {treated, disease, treatment_effects, confidences} = pat;
    const treated_for_p = treated[0];
    const max_life_gain = treatment_effects[disease];
    const treatments = getTreatmentCost();
    //The true life gain is either zero (wrong treatment) or the patient's maximal life gain (edited)
    pat['maximal_life_gain'] = max_life_gain;
    pat['true_life_gain'] = disease === treated[0] ? max_life_gain : 0;
    pat['treated_disease'] = treated_for_p;
    pat['expected_life_gain'] =
        treatment_effects[treated_for_p] * confidences[treated_for_p];
    pat['cost'] = treatments[treated_for_p];
    return pat;
  });
  //const patients = GameJSMock.patientsStat;
  return patients;
};

/**
 * Get Results in terms of  Object(cost, expected_life_gain, true_life_gain) after each reound
 * @returns {Object} #num # of patient
 */
const getResults = (patients) => {
  let costs = 0;
  let esl = 0;
  let tlf = 0;
  let mlf = 0;
  patients.forEach((patient) => {
    const {
      expected_life_gain,
      true_life_gain,
      cost,
      maximal_life_gain,
    } = patient;
    costs += cost;
    esl += expected_life_gain;
    tlf += true_life_gain;
    mlf += maximal_life_gain;
  });
  const results = {
    cost: costs,
    expected_life_gain: esl,
    true_life_gain: tlf,
    maximal_life_gain: mlf,
  };
  aggregate.roundSummary = results;
  return JSON.stringify(results);
};

const resetGame = () => {};

/**
 * returns the dictionary out of Enum TreatmentCost
 * @returns {Object} {healthy: 0, cold: 1, flue: 2, cancer: 3}
 */
const getTreatmentCost = () => {
  return GameJSMock.treatments;
};

const playRound = (patientsString) => {};

const endGame = () => {};
const prepareGame = (number) => {roundNumber = number};

const GameJS = {
  prepareGame,
  initGame,
  getRound,
  getTreatmentCost,
  getResults,
  resetGame,
  getSummary,
  getPatients,
  startRound,
  playRound,
  endGame,
};

export default GameJS;
