import * as React from "react";

function SvgCost(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" {...props}>
      <g
        transform="translate(1.529 1)"
        stroke="#FFF"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        opacity={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.282 5.395H7.729a1.658 1.658 0 100 3.316h2.368a1.658 1.658 0 010 3.315H6.071M8.913 3.5v11.368" />
        <circle cx={9} cy={9} r={9} />
      </g>
    </svg>
  );
}

export default SvgCost;
