import * as React from "react";

function SvgCold20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        stroke="#6CD0B7"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.5 9.5L21 3M2.5 19.5l2 2M2.5 12.5l9 9M3.5 20.5l4-4M5.5 15l6.857-6.5 3.143 3.143-6.857 6.5" />
      </g>
    </svg>
  );
}

export default SvgCold20;
