import * as React from "react";

function SvgTreatedDisease20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(2 2)"
        stroke="#6CD0B7"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 10.769v3.077M8.462 12.308h3.076M16.923 20H3.077A3.086 3.086 0 010 16.923v-9.23a3.086 3.086 0 013.077-3.078h13.846A3.086 3.086 0 0120 7.692v9.231A3.086 3.086 0 0116.923 20z" />
        <circle cx={10} cy={12.308} r={4.615} />
        <path d="M13.846 4.615H6.154V3.077A3.086 3.086 0 019.23 0h1.538a3.086 3.086 0 013.077 3.077v1.538z" />
      </g>
    </svg>
  );
}

export default SvgTreatedDisease20;
