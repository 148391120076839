import * as React from "react";

function SvgTrueLifeGain(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 23" {...props}>
      <g
        transform="translate(1 1)"
        stroke="#FFF"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        opacity={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6.182.773h4.636M8.5 3.864V.773M8.5 6.182h0M8.5 18.545h0M14.682 12.364h0M2.318 12.364h0M11.745 9.89h0c-.772-.85-2.009-.85-2.781 0l-.387.387c-.077.078-.077.078-.154 0l-.387-.386c-.772-.85-2.009-.85-2.781 0h0c-.773.85-.773 2.164 0 2.936l.463.464 2.705 2.859c.077.077.077.077.154.077l2.705-2.859.463-.463c.85-.85.85-2.241 0-3.014z" />
        <circle cx={8.5} cy={12.364} r={8.5} />
      </g>
    </svg>
  );
}

export default SvgTrueLifeGain;
