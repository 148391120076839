import * as React from "react";

function SvgMaximalLifeGain20(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g
        stroke="#61B6E1"
        strokeWidth={1.7}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.462 7.423H12l1.538-1.538 1.539 3.077 1.538-3.847 1.539 2.308M2 18.962l2-3.231c1.154-1.77 3.077-2.923 5.23-2.923h5.078a3.086 3.086 0 01-3.077 3.077H9.692" />
        <path d="M11.23 15.885h6.155l.923-1.231c.846-1.154 2.23-1.846 3.692-1.846h0L19.923 16.5c-1.077 2-3.23 3.23-5.461 3.23h0a18.324 18.324 0 00-10.154 3.078h0M12.077 12.808L8.692 9.192l-.923-1a4.183 4.183 0 010-5.769h0a3.86 3.86 0 015.616 0l.769.77c.077.076.23.076.308 0l.769-.77a3.86 3.86 0 015.615 0h0a4.183 4.183 0 010 5.77l-.923 1-3.308 3.384-2.153 2.23" />
      </g>
    </svg>
  );
}

export default SvgMaximalLifeGain20;
